import { NgModule } from "@angular/core";
import { DashboardLandingComponent } from "./components/dashboard-landing/dashboard-landing.component";
import { HttpClientModule } from '@angular/common/http';
import { SharedRailPerformanceModule } from "../shared-rail-performance/shared-rail-performance.module";
import { COMMON_KENDO_IMPORTS, COMMON_PRIME_NG_IMPORTS } from "../../common/common-imports";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";  
import { FusionChartsModule } from "angular-fusioncharts";
import FusionCharts from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { environment } from "../../../environments/environment";
import { DashboardRailcarComponent } from "./components/dashboard-reports/dashboard-railcar/dashboard-railcar.component";
import { CycleTimeAverageByLocationGraphComponent } from "./components/dashboard-landing/charts/cycle-time-average-by-location-graph/cycle-time-average-by-location-graph.component";
import { KpiMetricsComponent } from "./components/dashboard-landing/charts/kpi-metrics/kpi-metrics.component";
import { NetworkDwellAverageByLocationMapComponent } from "./components/dashboard-landing/charts/network-dwell-average-by-location-map/network-dwell-average-by-location-map.component";
import { RailcarVolumeHistoryGraphComponent } from "./components/dashboard-landing/charts/railcar-volume-history-graph/railcar-volume-history-graph.component";
import { DashboardCycleTimeComponent } from "./components/dashboard-reports/dashboard-cycle-time/dashboard-cycle-time.component";
import { DashboardFiltersComponent } from "./components/dashboard-reports/dashboard-filters/dashboard-filters.component";
import { DashboardNetworkDwellComponent } from "./components/dashboard-reports/dashboard-network-dwell/dashboard-network-dwell.component";
import { DashboardRailcarGraphComponent } from "./components/dashboard-reports/dashboard-railcar/dashboard-railcar-graph/dashboard-railcar-graph.component";
import { DashboardReportLayoutComponent } from "./components/dashboard-reports/dashboard-report-layout/components/dashboard-report-layout/dashboard-report-layout.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { DashboardRailcarTableComponent } from "./components/dashboard-reports/dashboard-railcar/dashboard-railcar-table/dashboard-railcar-table.component";
import { DashboardDwellByLocationMapComponent } from "./components/dashboard-reports/dashboard-network-dwell/dashboard-dwell-by-location-map/dashboard-dwell-by-location-map.component";
import { DashboardDwellByLocationChartComponent } from "./components/dashboard-reports/dashboard-network-dwell/dashboard-dwell-by-location-chart/dashboard-dwell-by-location-chart.component";
import { ReportEditNotificationComponent } from "./components/dashboard-reports/dashboard-report-layout/components/report-edit-notification/report-edit-notification.component";
import { DashboardDwellByLocationTableComponent } from "./components/dashboard-reports/dashboard-network-dwell/dashboard-dwell-by-location-table/dashboard-dwell-by-location-table.component";
import { DashboardDwellTotalChartComponent } from "./components/dashboard-reports/dashboard-network-dwell/dashboard-dwell-total-chart/dashboard-dwell-total-chart.component";

@NgModule({
    imports: [
        HttpClientModule,
        SharedRailPerformanceModule,
        ...COMMON_PRIME_NG_IMPORTS,
        ...COMMON_KENDO_IMPORTS,
        DashboardRoutingModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FusionChartsModule,
        FontAwesomeModule
    ],
    exports: [
    ],
    declarations: [
        DashboardRailcarComponent,
        DashboardCycleTimeComponent,
        DashboardNetworkDwellComponent,
        RailcarVolumeHistoryGraphComponent,
        NetworkDwellAverageByLocationMapComponent,
        CycleTimeAverageByLocationGraphComponent,
        KpiMetricsComponent,
        DashboardLandingComponent,
        DashboardRailcarGraphComponent,
        DashboardFiltersComponent,
        DashboardReportLayoutComponent,
        DashboardRailcarTableComponent,
        DashboardNetworkDwellComponent,
        DashboardDwellByLocationMapComponent,
        DashboardDwellByLocationChartComponent,
        DashboardDwellByLocationTableComponent,
        DashboardDwellTotalChartComponent,
        ReportEditNotificationComponent
    ],
    schemas: [],
})
export class DashboardModule { 
  constructor() {
    FusionChartsModule.fcRoot(FusionCharts, Column2D, FusionTheme);

    FusionCharts.options.creditLabel = false;
    (FusionCharts.options as any).license({
      key: environment.fusionChartApiKey,
      creditLabel: false,
    });
  }
}
