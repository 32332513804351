import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { NetworkDwellAverageByStateProvince } from '../models/network-dwell/network-dwell-average-by-state-province';
import { NetworkDwellPerformance } from '../models/network-dwell/network-dwell-performance';
import { NetworkDwellLocation } from '../models/network-dwell/network-dwell-location';
import { NetworkDwellCommodity } from '../models/network-dwell/network-dwell-commodity';
import { NetworkDwellStation } from '../models/network-dwell/network-dwell-station';
import { DashboardFilters } from '../models/filters/dashboard-filters';
import { DataQueryService } from '../../shared-rail-performance/services/data/data.service';
import { DataServiceConfiguration } from '../../shared-rail-performance/services/data/data-service-configuration';
import { NetworkDwellAverageByStation } from '../models/network-dwell/network-dwell-average-by-station';
import { OperatorFunction } from 'rxjs';
import { PagedResult } from '../../shared-rail-performance/models/paging/paged-result';
import { DataSource } from '../../shared-rail-performance/models/charts/fusion-charts/data-source';
import { NetworkDwellAverageByMonthStateProvince as NetworkDwellAverageByMonthLocation } from '../models/network-dwell/network-dwell-average-by-month-state-province';
import { NetworkDwellStatus } from '../models/network-dwell/status/network-dwell-status';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { NetworkDwellStatusRecord } from '../models/network-dwell/status/network-dwell-status-record';
import { PagedQuery } from '../../shared-rail-performance/services/data/paged-query';
import { NetworkDwellFilters } from '../models/filters/network-dwell-filters';
import { NetworkDwellCount } from '../models/network-dwell/network-dwell-count';

@Injectable({
  providedIn: 'root'
})
export class NetworkDwellDataService extends DataQueryService {
  getNetworkDwellAveragesByStateProvince(filters: NetworkDwellFilters) {
    return this.post(NetworkDwellDataServiceConfiguration.NetworkDwellAverageByStateProvince, filters);
  }

  getNetworkDwellAveragesByStateProvinceLastMonth() {
    return this.post(NetworkDwellDataServiceConfiguration.NetworkDwellAverageByStateProvinceLastMonth);
  }

  getNetworkDwellAveragesByMonthLocationChart(filters: NetworkDwellFilters, transform: OperatorFunction<NetworkDwellAverageByMonthLocation[], DataSource>) {
    return this.postWithTransform(NetworkDwellDataServiceConfiguration.NetworkDwellAverageByMonthLocationChart, transform, filters);
  }

  getNetworkDwellAveragesByStation(filters: NetworkDwellFilters) {
    return this.post(NetworkDwellDataServiceConfiguration.NetworkDwellAverageByStation, filters);
  }

  getNetworkDwellSummary(filters: NetworkDwellFilters) {
    return this.post(NetworkDwellDataServiceConfiguration.NetworkDwellSummary, filters);
  }

  getNetworkDwellByCountry(filters: NetworkDwellFilters) {
    return this.post(NetworkDwellDataServiceConfiguration.NetworkDwellAverageByCountry, filters);
  }

  getNetworkDwellCommodity(filters: NetworkDwellFilters) {
    return this.post(NetworkDwellDataServiceConfiguration.NetworkDwellCommodity, filters);
  }

  getNetworkDwellStation(filters: NetworkDwellFilters) {
    return this.post(NetworkDwellDataServiceConfiguration.NetworkDwellStation, filters);
  }

  getNetworkDwellByStatus(filters: NetworkDwellFilters) {
    return this.post(NetworkDwellDataServiceConfiguration.NetworkDwellStatus, filters);
  }

  getNetworkDwellByStatusTableData(filters: NetworkDwellFilters, transform: OperatorFunction<PagedResult<NetworkDwellStatusRecord, NetworkDwellFilters>, GridDataResult>) {
    return this.postWithTransform(NetworkDwellDataServiceConfiguration.NetworkDwellStatus, transform, filters);
  }

  getNetworkDwellCount(filters: NetworkDwellFilters, transform: OperatorFunction<NetworkDwellCount[], DataSource>) {
    return this.postWithTransform(NetworkDwellDataServiceConfiguration.NetworkDwellCount, transform, filters);
  }
}

abstract class NetworkDwellDataServiceConfiguration {
  static readonly NetworkDwellAverageByStateProvince = new DataServiceConfiguration<NetworkDwellAverageByStateProvince[]>(
    'network-dwell-average-by-state-province',
    environment.apiUrl + 'NetworkDwell/Average/StateProvince'
  );

  static readonly NetworkDwellAverageByStateProvinceLastMonth = new DataServiceConfiguration<NetworkDwellAverageByStateProvince[]>(
    'network-dwell-average-by-state-province-last-month',
    environment.apiUrl + 'NetworkDwell/Average/StateProvince/LastMonth'
  );

  static readonly NetworkDwellAverageByMonthLocationChart = new DataServiceConfiguration<DataSource>(
    'network-dwell-average-by-month-location-chart',
     environment.apiUrl + 'NetworkDwell/Average/Month/Location'
  );

  static readonly NetworkDwellAverageByStation = new DataServiceConfiguration<NetworkDwellAverageByStation[]>(
    'network-dwell-average-by-station',
     environment.apiUrl + 'NetworkDwell/Average/Station'
  );

  static readonly NetworkDwellSummary = new DataServiceConfiguration<NetworkDwellPerformance>(
    'network-dwell-summary',
    environment.apiUrl + 'NetworkDwell/Summary',
  );

  static readonly NetworkDwellAverageByCountry = new DataServiceConfiguration<NetworkDwellLocation>( 
    'network-dwell-average-by-country',
    environment.apiUrl + 'NetworkDwell/Average/Country'
  );

  static readonly NetworkDwellCommodity = new DataServiceConfiguration<NetworkDwellCommodity>( 
    'network-dwell-commodity',
    environment.apiUrl + 'NetworkDwell/Commodity'
  );

  static readonly NetworkDwellStation = new DataServiceConfiguration<NetworkDwellStation>(
    'network-dwell-station',
    environment.apiUrl + 'NetworkDwell/Station'
  );

  static readonly NetworkDwellStatus = new DataServiceConfiguration<PagedResult<NetworkDwellStatusRecord, NetworkDwellFilters>>(
    'network-dwell-status',
    environment.apiUrl + 'NetworkDwell/Status'
  );

  static readonly NetworkDwellCount = new DataServiceConfiguration<NetworkDwellCount>(
    'network-dwell-count',
    environment.apiUrl + 'NetworkDwell/Count'
  );
}