import { Component, OnInit, Input, ViewChild, OnDestroy } from "@angular/core";
import { ExpansionPanelComponent } from "@progress/kendo-angular-layout";
import { QueryObserverResult } from "@tanstack/query-core";
import { BehaviorSubject, Observable } from "rxjs";
import { Country } from "../../../../shared-rail-performance/models/location/country";
import { StateProvince } from "../../../../shared-rail-performance/models/location/state-province";
import { CommodityDataService } from "../../../../shared-rail-performance/services/commodity-data.service";
import { CustomerDataService } from "../../../../shared-rail-performance/services/customer-data.service";
import { LocationDataService } from "../../../../shared-rail-performance/services/location-data.service";
import { MotionStatuses } from "../../../constants/motion-status";
import { LoadedStatuses } from "../../../constants/loaded-status";
import { Region } from "../../../../shared-rail-performance/models/location/region";
import { Station } from "../../../../shared-rail-performance/models/location/station";
import { SubSink } from "subsink";
import { DashboardType } from "../../../constants/dashboard-type";
import { DashboardReportFormService } from "../dashboard-report-layout/services/dashboard-report-form.service";
import { TravelStatuses } from "../../../constants/travel-status";

@Component({
  selector: 'app-dashboard-filters',
  templateUrl: './dashboard-filters.component.html',
  styleUrl: './dashboard-filters.component.scss',
})
export class DashboardFiltersComponent implements OnInit, OnDestroy {
  @Input() showFilters$ = new BehaviorSubject<boolean>(false);
  @ViewChild('filterExpansionPanel') filterExpansionPanel?: ExpansionPanelComponent;

  public customersQueryResults$?: Observable<QueryObserverResult<string[], Error>>;
  public countriesQueryResults$?: Observable<QueryObserverResult<Country[], Error>>;
  public statesProvincesQueryResults$?: Observable<QueryObserverResult<StateProvince[], Error>>;
  public stationsQueryResults$?: Observable<QueryObserverResult<Station[], Error>>;
  public regionsQueryResults$?: Observable<QueryObserverResult<Region[], Error>>;
  public commoditiesQueryResults$?: Observable<QueryObserverResult<string[], Error>>;

  public inMotionValues = MotionStatuses;
  public travelStatusValues = TravelStatuses;
  public loadedValues = LoadedStatuses;
  public regionValues = [];

  public DashboardType = DashboardType;

  public virtual: any = {
    itemHeight: 28,
  };

  private sub = new SubSink();

  constructor(private customerDataService: CustomerDataService, 
              private locationDataService: LocationDataService, 
              private commodityDataService: CommodityDataService,
              public dashboardFormService: DashboardReportFormService) {
              }

  ngOnInit(): void {
    this.customersQueryResults$ = this.customerDataService.getCustomers().result$;
    this.countriesQueryResults$ = this.locationDataService.getCountries().result$;
    this.statesProvincesQueryResults$ = this.locationDataService.getStatesProvinces().result$;
    this.stationsQueryResults$ = this.locationDataService.getStations().result$;
    this.regionsQueryResults$ = this.locationDataService.getRegions().result$;
    this.commoditiesQueryResults$ = this.commodityDataService.getCommodities().result$;

    this.sub.sink = this.customersQueryResults$.subscribe((result) => {
      if (result.isLoading) {
        this.dashboardFormService.commonFiltersFormGroup.get('customers')?.disable();
      }
      else {
        this.dashboardFormService.commonFiltersFormGroup.get('customers')?.enable();
      }
    });

    this.sub.sink = this.countriesQueryResults$.subscribe((result) => {
      if (result.isLoading) {
        this.dashboardFormService.commonFiltersFormGroup.get('countries')?.disable();
      }
      else {
        this.dashboardFormService.commonFiltersFormGroup.get('countries')?.enable();
      }
    });

    this.sub.sink = this.statesProvincesQueryResults$.subscribe((result) => {
      if (result.isLoading) {
        this.dashboardFormService.commonFiltersFormGroup.get('statesProvinces')?.disable();
      }
      else {
        this.dashboardFormService.commonFiltersFormGroup.get('statesProvinces')?.enable();
      }
    });

    this.sub.sink = this.commoditiesQueryResults$.subscribe((result) => {
      if (result.isLoading) {
        this.dashboardFormService.commonFiltersFormGroup.get('commodities')?.disable();
      }
      else {
        this.dashboardFormService.commonFiltersFormGroup.get('commodities')?.enable();
      }
    });

    this.sub.sink = this.showFilters$.subscribe((showFilters) => {
      if (this.filterExpansionPanel?.expanded != showFilters) {
        this.filterExpansionPanel?.toggle();
      }
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  applyFilters() {
    this.dashboardFormService.applyFilters();
  }

  clearFilters() {
    this.dashboardFormService.filtersFormGroup.reset();
    this.dashboardFormService.filtersFormGroup.markAsPristine();
    this.applyFilters();
  }
}
