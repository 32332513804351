import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { LocationFilters } from '../models/filters/location-filters';
import { environment } from '../../../../environments/environment';
import { DataQueryService } from './data/data.service';
import { DataServiceConfiguration } from './data/data-service-configuration';
import { Country } from '../models/location/country';
import { StateProvince } from '../models/location/state-province';
import { Station } from '../models/location/station';
import { Region } from '../models/location/region';
import { SubSink } from 'subsink';
import { combineLatest, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationDataService extends DataQueryService {
  override prefetch(): Observable<any> {
    return combineLatest([
      this.prefetchGet(LocationDataServiceConfiguration.AllCountries),
      this.prefetchGet(LocationDataServiceConfiguration.AllStatesProvinces),
      this.prefetchGet(LocationDataServiceConfiguration.AllStations),
      this.prefetchGet(LocationDataServiceConfiguration.AllRegions)
    ]);
  }

  getCountries(filters?: LocationFilters) {
    return this.get<Country[]>(LocationDataServiceConfiguration.AllCountries);
  }

  getStatesProvinces(filters?: LocationFilters) {
    return this.get<StateProvince[]>(LocationDataServiceConfiguration.AllStatesProvinces);
  }

  getStations(filters?: LocationFilters) {
    return this.get<Station[]>(LocationDataServiceConfiguration.AllStations);
  }

  getRegions(filters?: LocationFilters) {
    return this.get<Region[]>(LocationDataServiceConfiguration.AllRegions);
  }
}

abstract class LocationDataServiceConfiguration {
  static readonly AllCountries = new DataServiceConfiguration<Country[]>(
    'countries',
      environment.apiUrl + 'Location/Countries',
      Infinity
  );

  static readonly AllStatesProvinces = new DataServiceConfiguration<string[]>(
    'states-provinces',
     environment.apiUrl + 'Location/States',
     Infinity
  );

  static readonly AllStations = new DataServiceConfiguration<string[]>(
    'stations',
     environment.apiUrl + 'Location/Stations',
     Infinity
  );

  static readonly AllRegions = new DataServiceConfiguration<string[]>(
    'regions',
     environment.apiUrl + 'Location/Regions',
     Infinity
  );
}
