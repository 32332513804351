export const Period = {
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Quarterly: 'Quarterly',
    Annually: 'Annually'
};

export const Periods = [
    Period.Daily,
    Period.Weekly,
    Period.Monthly,
    Period.Quarterly,
    Period.Annually
];