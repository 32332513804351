
<div class="content-width">
  <div class="dashboard">
    <div class="dashboard-title">
      My Dashboard
    </div>
    <div class="dashboard-cards">
      <div class="dashboard-card">
        <div class="visual_title">Railcar Volume</div>
        <div class="visual_subtitle">Total Railcars Placed</div>
        <div class="card">
          <app-railcar-volume-history-graph></app-railcar-volume-history-graph>
        </div>
      </div>
      <div class="dashboard-card">
        <div class="visual_title">Network Dwell</div>
        <div class="visual_subtitle">Location Average Dwell</div>
        <div class="card h-100">
          <app-network-dwell-average-by-location-map></app-network-dwell-average-by-location-map>
        </div>
      </div>
      <div class="dashboard-card">
        <div class="visual_title">Cycle Time</div>
        <div class="visual_subtitle">Location Average Cycle Time</div>
        <div class="card">
          <app-cycle-time-average-by-location-graph></app-cycle-time-average-by-location-graph>
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard">
    <div class="dashboard-title">
      Performance Metrics
    </div>
    <div class="dashboard-divider"></div>
    <div class="dashboard-subtitle">
      {{ performance_date }}
    </div>
    <app-kpi-metrics></app-kpi-metrics>
  </div>
</div>