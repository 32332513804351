import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { injectMutation } from '@ngneat/query';
import { Report } from '../models/report/report';
import { environment } from '../../../../environments/environment';
import { DataQueryService } from '../../shared-rail-performance/services/data/data.service';
import { DataServiceConfiguration } from '../../shared-rail-performance/services/data/data-service-configuration';
import { map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportDataService extends DataQueryService {
  constructor(private httpClient: HttpClient) { 
    super(httpClient);
  }

  getAllForType(type: string) {
    return this.get(ReportDataServiceConfiguration.ReportsAll, { type });
  }

  save(report: Report) {
    return this.httpClient.post<number>(`${environment.apiUrl}Report`, report).pipe(tap(() => {
      return this.refetchAnyQuery(ReportDataServiceConfiguration.ReportsAll.key);
    }));
  }

  delete(id: number) {
    return this.httpClient.delete(`${environment.apiUrl}Report/${id}`).pipe(tap(() => {
      return this.refetchAnyQuery(ReportDataServiceConfiguration.ReportsAll.key);
    }));
  }
}

class ReportDataServiceConfiguration {
  static readonly ReportsAll = new DataServiceConfiguration<Report[]>(
      'report',
       environment.apiUrl + 'Report'
  );
}