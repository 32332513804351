import { Component } from '@angular/core';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-dashboard-landing',
  templateUrl: './dashboard-landing.component.html',
  styleUrl: './dashboard-landing.component.scss'
})
export class DashboardLandingComponent {
  
  temp_data: any = null;

  performance_date: string = "04/24/2024"
  

  constructor() {
    this.performance_date = formatDate(Date(), 'M/dd/yyyy', 'en-US');
  }

  // draw_cycletime() {
  //   const documentStyle = getComputedStyle(document.documentElement);
  //   const textColor = documentStyle.getPropertyValue('--text-color');
  //   const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
  //   const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

  //   this.cycletime_data = {
  //     labels: ['May 2022', 'JUN 2022', 'JUL 2022', 'AUG 2022', 'SEP 2022', 'OCT 2022', 'NOV 2022', 'DEC 2022'],
  //     datasets: [
  //       {
  //         type: 'line',
  //         label: 'BC',
  //         data: [1, 0, 3, 11, 6, 7, 3, 6, 0],
  //         fill: false,
  //         borderColor: 'darkblue',
  //         tension: 0.1
  //       },
  //       {
  //         type: 'line',
  //         label: 'AB',
  //         data: [0, 8, 6, 2, 1, 3, 0, 0, 1],
  //         fill: false,
  //         borderColor: 'yellow',
  //         tension: 0.0
  //       },
  //       {
  //         type: 'line',
  //         label: 'SK',
  //         data: [7, 7, 4, 5, 2, 0, 2, 4],
  //         fill: false,
  //         borderColor: 'green',
  //         tension: 0.0
  //       },
  //       {
  //         type: 'line',
  //         label: 'MN',
  //         data: [1, 3, 5, 3, 9, 5, 10, 3],
  //         fill: false,
  //         borderColor: 'lightblue',
  //         tension: 0.0
  //       }
  //     ]
  //   };

  //   this.cycletime_options = {
  //     maintainAspectRatio: true,
  //     aspectRatio: 1.6,
  //     plugins: { legend: { labels: { color: textColor } } },
  //     scales: {
  //       x: {
  //         ticks: { color: textColorSecondary },
  //         grid: { color: surfaceBorder, drawBorder: false }
  //       },
  //       y: {
  //         ticks: {},
  //         grid: {
  //           color: surfaceBorder, drawBorder: false
  //         }
  //       }
  //     }
  //   };
  // }
}

