import { ElementRef, NgZone } from '@angular/core';
import { environment } from '../../../../environments/environment';
import WebMap from '@arcgis/core/WebMap';
import MapView from '@arcgis/core/views/MapView';
import * as promiseUtils from "@arcgis/core/core/promiseUtils";
import GeoJSONLayer from '@arcgis/core/layers/GeoJSONLayer';
import esriConfig from "@arcgis/core/config";
import Color from "@arcgis/core/Color";
import Graphic from "@arcgis/core/Graphic";
import { BehaviorSubject } from 'rxjs';
import GeoJSONLayerView from '@arcgis/core/views/layers/GeoJSONLayerView';

export abstract class MapService {
    private highlightedFeatures: __esri.Handle[] = [];

    protected graphicAdded$ = new BehaviorSubject<Graphic | undefined>(undefined);
    protected graphicHighlighted$ = new BehaviorSubject<Graphic | undefined>(undefined);

    constructor(protected ngZone: NgZone) {
        esriConfig.apiKey = environment.arcgisApiKey;
    }

    protected loadMap(mapViewElement: ElementRef, loadMapOptions: Partial<LoadMapOptions>): Promise<MapView> {
        const container = mapViewElement.nativeElement;

        const webMap = new WebMap({
            basemap: loadMapOptions.baseMap ?? 'gray',
            layers: loadMapOptions.layers ?? []
        });

        const mapView = new MapView({
            container,
            map: webMap,
            center: [-106.753075, 52.104184],
            zoom: loadMapOptions.zoomLevel ?? 1,
        });

        if (!!loadMapOptions.highlightOptions) {
            mapView.highlightOptions = {
                color: loadMapOptions.highlightOptions.color,
                fillOpacity: loadMapOptions.highlightOptions.fillOpacity,
                haloColor: loadMapOptions.highlightOptions.haloColor,
                haloOpacity: loadMapOptions.highlightOptions.haloOpacity
            };
        }

        mapView.ui.move("zoom", "bottom-left");

        if (!!loadMapOptions.expandButtonClick) {
            this.addExpandButton(mapView, loadMapOptions.expandButtonClick);
        }

        return mapView.when().then((view: MapView) => {
            view.graphics.on('after-add', (event: any) => {
                for (let graphic of event.target.items) {
                    this.graphicAdded$.next(graphic);
                }
            });

            if (!!loadMapOptions.highlightOptions) {
                this.debouncedHighlightHandler(view, loadMapOptions.highlightOptions?.layer, this.highlightedFeatures, this.graphicHighlighted$);
            }

            return view;
        });
    }

    private addExpandButton(mapView: MapView, click: () => void) {
        var element = document.createElement('div');
        element.className = "esri-widget--button esri-widget esri-icon-zoom-out-fixed";
        element.addEventListener('click', click);
        mapView.ui.add(element, "top-right");
    }

    private eventHandler(mapView: MapView, event: any, layer: GeoJSONLayer, callback: (event: any, mapView: MapView) => void) {
        const opts = {
            include: layer,
        };

        let callbackWithMapView = (event: any) => {
            return callback(event, mapView);
        };

        mapView.hitTest(event, opts).then(callbackWithMapView);
    }

    private debouncedHighlightHandler(mapView: MapView, layer: any, highlightedFeatures: __esri.Handle[], graphicHighlighted$: BehaviorSubject<Graphic | undefined>) {
        const opts = {
            include: layer,
        };

        const debouncedHandler = promiseUtils.debounce((event: any) => {
            mapView.hitTest(event, opts).then((hitTestResult: any) => {
                if (hitTestResult.results.length) {
                    const graphic = hitTestResult.results[0].graphic as Graphic;
                    let layerView = mapView.layerViews.find(x => x.layer == graphic.layer) as GeoJSONLayerView;

                    if (highlightedFeatures.length) {
                        for (let highlight of highlightedFeatures) {
                            highlight.remove();
                        }

                        highlightedFeatures = [];
                    }

                    highlightedFeatures.push(layerView.highlight(graphic));
                    this.graphicHighlighted$.next(graphic);
                }
            });
        });

        mapView.on("click", debouncedHandler);

        return mapView;
    }

    debouncedClickHandler(mapView: MapView, layer: any, callback: any) {
        const debouncedHandler = promiseUtils.debounce((event: any) => {
            this.eventHandler(mapView, event, layer, callback);
        });

        mapView.on("click", debouncedHandler);

        return mapView;
    }

    debouncedHoverHandler(mapView: MapView, layer: any, callback: any) {
        const debouncedHandler = promiseUtils.debounce((event: any) =>
            this.eventHandler(mapView, event, layer, callback)
        );

        mapView.on("pointer-move", debouncedHandler);

        return mapView;
    }

    disableZooming(view: any) {
        view.popup.actions = [];

        function stopEvtPropagation(event: any) {
            event.stopPropagation();
        }

        view.ui.components = ["attribution"];
        view.on("mouse-wheel", stopEvtPropagation);
        view.on("double-click", stopEvtPropagation);
        view.on("double-click", ["Control"], stopEvtPropagation);
        view.on("drag", stopEvtPropagation);
        view.on("drag", ["Shift"], stopEvtPropagation);
        view.on("drag", ["Shift", "Control"], stopEvtPropagation);

        view.on("key-down", (event: any) => {
            const prohibitedKeys = ["+", "-", "Shift", "_", "=", "ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft"];
            const keyPressed = event.key;
            if (prohibitedKeys.indexOf(keyPressed) !== -1) {
                event.stopPropagation();
            }
        });

        return view;
    }
}

export interface LoadMapOptions {
    baseMap: string;
    layers: any[];
    zoomLevel: number;
    highlightOptions: Partial<LoadMapHighlightOptions>;
    expandButtonClick: () => void;
}

export interface LoadMapHighlightOptions {
    layer: any;
    haloColor: Color;
    color: Color;
    fillOpacity: number;
    haloOpacity: number;
}