export const MotionStatus = {
    InMotion: 'In-Motion',
    AtOrigin: 'At Origin',
    AtDestination: 'At Destination'
};

export const MotionStatuses = [
    MotionStatus.InMotion,
    MotionStatus.AtOrigin,
    MotionStatus.AtDestination
];