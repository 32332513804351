import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import WebMap from '@arcgis/core/WebMap';
import MapView from '@arcgis/core/views/MapView';
import Bookmarks from '@arcgis/core/widgets/Bookmarks';
import Expand from '@arcgis/core/widgets/Expand';
import Feature from '@arcgis/core/widgets/Feature';
import FeatureSet from '@arcgis/core/rest/support/FeatureSet';
import * as promiseUtils from "@arcgis/core/core/promiseUtils";
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import GeoJSONLayer from '@arcgis/core/layers/GeoJSONLayer';

import esriConfig from "@arcgis/core/config";
import Color from "@arcgis/core/Color";
import Graphic from "@arcgis/core/Graphic";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import ColorVariable from "@arcgis/core/renderers/visualVariables/ColorVariable";
import GeoJSONLayerView from "@arcgis/core/views/layers/GeoJSONLayerView";
import Field from "@arcgis/core/layers/support/Field";
import { MapService } from '../../../../../shared-rail-performance/services/map-service';
import * as stateProvinceGeoJson from '../../../../../../../assets/geo-json/states-geo.json';
import { FeatureCollection } from '../../../../../shared-rail-performance/models/maps/feature-collection';
import { NetworkDwellDataService } from '../../../../services/network-dwell-data.service';
import { NetworkDwellAverageByStateProvince } from '../../../../models/network-dwell/network-dwell-average-by-state-province';
import { QueryObserverResult } from '@tanstack/query-core';
import { BehaviorSubject, Observable } from 'rxjs';
import ColorStop from "@arcgis/core/renderers/visualVariables/support/ColorStop";
import { NetworkDwellAverageByStation } from '../../../../models/network-dwell/network-dwell-average-by-station';
import SizeVariable from "@arcgis/core/renderers/visualVariables/SizeVariable";
import { SubSink } from 'subsink';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DashboardDwellMapService extends MapService implements OnDestroy {
  stateProvinceSelected$ = new BehaviorSubject<string>('');
  stationSelected$ = new BehaviorSubject<string>('');

  colorVariableStops$ = new BehaviorSubject<any[]>([]);

  private sub = new SubSink();

  constructor(ngZone: NgZone) {
    super(ngZone);

    this.sub.sink = this.graphicAdded$.subscribe((graphic?: Graphic) => {
      if (graphic) {
        this.stateProvinceSelected$.next(graphic.getAttribute('state-province-name'));
      }
    });

    this.sub.sink = this.graphicHighlighted$.subscribe((graphic?: Graphic) => {
      if (graphic) {
        this.stationSelected$.next(graphic.getAttribute('city'));
      }
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  loadColorVariableStops(max: number, mean?: number) {
    let adjustedMax = Math.max(Math.ceil(max), 1);
    let middle = mean ?? adjustedMax / 2;

    this.colorVariableStops$.next([
      {
        value: 0,
        color: new Color([140, 192, 89]),
        size: 4
      },
      {
        value: middle,
        color: new Color([243, 207, 96]),
        size: 22
      },
      {
        value: adjustedMax,
        color: new Color([212, 104, 93]),
        size: 40
      }
    ]);
  }

  loadStation(mapViewElement: ElementRef, networkDwellAverageByStation: NetworkDwellAverageByStation[], expandButtonClick?: () => void) {
    return this.ngZone.runOutsideAngular(async () => {
      if (networkDwellAverageByStation) {
        let max = Math.max(...networkDwellAverageByStation.map(x => x.averageDwell ?? 0));
        let mean = _.mean(networkDwellAverageByStation.map(x => x.averageDwell ?? 0));

        this.loadColorVariableStops(max, mean);
      }

      const stationMarkerSymbol = new SimpleMarkerSymbol({
        color: new Color('green'),
        style: "circle",
        size: "8px",
        outline: {
          width: 0
        }
      });

      const stationRenderer = new SimpleRenderer({
        symbol: stationMarkerSymbol,
        label: "Station",
        visualVariables: [
          new SizeVariable({
            field: 'network-dwell-average',
            stops: this.colorVariableStops$.value
          }),
          new ColorVariable({
            field: 'network-dwell-average',
            stops: this.colorVariableStops$.value
          })
        ]
      });

      let stationGeoJson = this.generateGeoJsonForStations(networkDwellAverageByStation);
      let stationGeoJsonBlob = new Blob([JSON.stringify(stationGeoJson)], {
        type: "application/json"
      });

      let stationLayer = new GeoJSONLayer({
        url: URL.createObjectURL(stationGeoJsonBlob),
        renderer: stationRenderer,
        id: 'station-layer',
        outFields: ['*'],
        fields: [
          {
            type: 'string',
            name: 'state-province-code'
          },
          {
            type: 'string',
            name: 'city'
          },
          {
            type: 'double',
            name: 'network-dwell-average'
          }
        ],
      })

      return this.loadMap(mapViewElement, {
        layers: [stationLayer],
        zoomLevel: 2,
        expandButtonClick,
        highlightOptions: {
          layer: stationLayer,
          haloColor: new Color('white'),
          color: new Color('white'),
          fillOpacity: .5,
          haloOpacity: 1,
        }
      }).then((view: MapView) => {
        //this.disableZooming(view);
        return view;
      })
        .then((view: MapView) => {
          this.debouncedClickHandler(view, stationLayer, this.clickedStationEvent);
          return view;
        });
    });
  }

  expandButtonClick() {

  }

  loadStateProvince(mapViewElement: ElementRef, networkDwellAverageByStateProvince: NetworkDwellAverageByStateProvince[], expandButtonClick?: () => void) {
    return this.ngZone.runOutsideAngular(async () => {
      if (networkDwellAverageByStateProvince) {
        let max = Math.max(...networkDwellAverageByStateProvince.map(x => x.averageDwell ?? 0));
        let mean = _.mean(networkDwellAverageByStateProvince.map(x => x.averageDwell ?? 0));

        this.loadColorVariableStops(max, mean);
      }

      const defaultSym = new SimpleFillSymbol({
        color: new Color("white")
      });

      const renderer = new SimpleRenderer({
        symbol: defaultSym,
        label: "State",
        visualVariables: [
          new ColorVariable({
            field: "network-dwell-average",
            stops: this.colorVariableStops$.value
          })
        ]
      });

      let geoJson = this.populateStateProvinceMapData(stateProvinceGeoJson, networkDwellAverageByStateProvince);
      let geoJsonBlob = new Blob([JSON.stringify(geoJson)], {
        type: "application/json"
      });

      let stateProvinceLayer = new GeoJSONLayer({
        url: URL.createObjectURL(geoJsonBlob),
        renderer,
        outFields: ['*'],
        fields: [
          {
            type: 'string',
            name: 'state-province-name'
          },
          {
            type: 'string',
            name: 'state-province-code'
          },
          {
            type: 'double',
            name: 'network-dwell-average',
            defaultValue: 0
          }
        ],
      });

      return this.loadMap(mapViewElement, {
        layers: [stateProvinceLayer],
        zoomLevel: 2,
        expandButtonClick,
      }).then((view: MapView) => {
        //this.disableZooming(view);
        return view;
      }).then((view: MapView) => {
          this.debouncedClickHandler(view, stateProvinceLayer, this.clickedStateProvinceEvent);
          return view;
        });
    });
  }

  populateStateProvinceMapData(stateProvinceGeoJson: FeatureCollection, networkDwellAverageByStateProvinces: NetworkDwellAverageByStateProvince[]) {
    for (let networkDwellAverageByStateProvince of networkDwellAverageByStateProvinces) {
      let stateProvinceGeoJsonRecord = stateProvinceGeoJson.features.find(x => x.properties['state-province-code'] === networkDwellAverageByStateProvince.stateProvinceCode);

      if (stateProvinceGeoJsonRecord) {
        stateProvinceGeoJsonRecord.properties['network-dwell-average'] = networkDwellAverageByStateProvince.averageDwell;
      }
    }

    return stateProvinceGeoJson;
  }

  private generateGeoJsonForStations(networkDwellAverageByStations: NetworkDwellAverageByStation[]) {
    let featureCollection: FeatureCollection = {
      type: 'FeatureCollection',
      features: []
    };

    networkDwellAverageByStations.forEach((station) => {
      featureCollection.features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            station.longitude,
            station.latitude
          ]
        },
        properties: {
          'state-province-code': station.stateProvinceCode,
          'city': station.city,
          'network-dwell-average': station.averageDwell,
          'railcar-count': station.railcarCount
        }
      })
    });

    return featureCollection;
  }

  private clickedStateProvinceEvent(response: any, mapView: MapView) {
    if (response.results.length) {
      const graphic = response.results[0].graphic as Graphic;

      const attributes = graphic.attributes;
      const name = attributes['state-province-name'];

      const outlineSymbol = new SimpleLineSymbol({
        color: new Color('white'),
        width: 4,
        style: 'solid'
      });

      let previousHighlighted = mapView.graphics.find(x => !!x.getAttribute('highlight'));
      mapView.graphics.remove(previousHighlighted);

      let highlightedGraphic = graphic.clone();
      highlightedGraphic.symbol = outlineSymbol;
      highlightedGraphic.setAttribute('highlight', true);

      mapView.graphics.add(highlightedGraphic);
    }
  }

  private clickedStationEvent(response: any, mapView: MapView) {
    if (response.results.length) {
      const graphic = response.results[0].graphic as Graphic;
      const attributes = graphic.attributes;
      const name = attributes['state-province-code'];
    }
  }
}
