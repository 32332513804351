import { Component, OnDestroy, OnInit } from '@angular/core';
import { QueryObserverResult } from '@tanstack/query-core';
import { combineLatest, map, Observable, startWith } from 'rxjs';
import { DataSource } from '../../../../../shared-rail-performance/models/charts/fusion-charts/data-source';
import { NetworkDwellDataService } from '../../../../services/network-dwell-data.service';
import { Categories } from '../../../../../shared-rail-performance/models/charts/fusion-charts/categories';
import { ChartSeries } from '../../../../../shared-rail-performance/models/charts/fusion-charts/chart-series';
import { NetworkDwellAverageByMonthStateProvince as NetworkDwellAverageByMonthLocation } from '../../../../models/network-dwell/network-dwell-average-by-month-state-province';
import { groupBy } from '../../../../../shared-rail-performance/functions/array-functions';
import { sortBy } from 'sort-by-typescript';
import { SubSink } from 'subsink';
import { DashboardReportFormService } from '../../dashboard-report-layout/services/dashboard-report-form.service';
import { NetworkDwellFilters } from '../../../../models/filters/network-dwell-filters';
import { DashboardType } from '../../../../constants/dashboard-type';
import { DashboardNetworkDwellFormService } from '../services/dashboard-network-dwell-form.service';
import { LocationType } from '../../../../constants/location-type';
import { Collection } from 'lodash';

@Component({
  selector: 'app-dashboard-dwell-by-location-chart',
  templateUrl: './dashboard-dwell-by-location-chart.component.html',
  styleUrl: './dashboard-dwell-by-location-chart.component.css',
})
export class DashboardDwellByLocationChartComponent implements OnInit, OnDestroy {
  private sub = new SubSink();

  constructor(private networkDwellFormService: DashboardNetworkDwellFormService,
              private dashboardFormService: DashboardReportFormService
  ) {}

  ngOnInit(): void {
    this.networkDwellFormService.queryDwellByLocationTypeData();

    this.sub.sink = combineLatest([
      this.networkDwellFormService.yearsFormControl.valueChanges.pipe(startWith([])), 
      this.networkDwellFormService.monthsFormControl.valueChanges.pipe(startWith([])),
      this.networkDwellFormService.graphLocationTypeControl.valueChanges,
      this.dashboardFormService.filtersApplied$.pipe(startWith(undefined as void))]).subscribe(() => {
        this.networkDwellFormService.queryDwellByLocationTypeData();
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  get dwellByLocationTypeQueryResult$() {
    return this.networkDwellFormService.dwellByLocationQueryResult$;
  }
}
