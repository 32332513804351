import { Component, Input } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { QueryObserverResult } from '@tanstack/query-core';
import { DataSource } from '../../models/charts/fusion-charts/data-source';

@Component({
  selector: 'app-control-status-indicator',
  templateUrl: './control-status-indicator.component.html',
  styleUrl: './control-status-indicator.component.scss',
})
export class ControlStatusIndicatorComponent {
  @Input() queryObserverResult!: QueryObserverResult<GridDataResult, Error> | QueryObserverResult<DataSource, Error> | QueryObserverResult<any[], Error>;

  showNoDataMessage(result: QueryObserverResult<GridDataResult | DataSource | any[], Error>): boolean {
    if (result.data && "data" in result.data && "total" in result.data) {
      return result.data.data.length === 0;
    }
    else if (result.data && "categories" in result.data && "chart" in result.data) {
      return (result.data.data?.length ?? 0) === 0 && (result.data.dataset?.length === 0);
    }
    else if (Array.isArray(result.data)) {
      return result.data.length === 0;
    }
    else {
      return false;
    }
  }
}
