<div class="overlay top-index" *ngIf="showOverlay">
    <div
        class="h-100 d-flex flex-column align-items-center justify-content-center position-absolute w-100 top-index">
        <div class="spinner-border spinner-border-white" role="status">
        </div>
    </div>
</div>
<div *ngIf="!showOverlay" class="h-100 d-flex flex-column align-items-center justify-content-center position-absolute w-100 top-index">
    <div class="spinner-border" role="status">
    </div>
</div>
