import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { CommodityFilters } from '../models/filters/commodity-filters';
import { environment } from '../../../../environments/environment';
import { DataQueryService } from './data/data.service';
import { DataServiceConfiguration } from './data/data-service-configuration';
import { SubSink } from 'subsink';
import { combineAll, combineLatest, forkJoin, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommodityDataService extends DataQueryService {
  override prefetch(): Observable<any> {
    return combineLatest([this.prefetchGet(CommodityDataServiceConfiguration.AllCommodities)]);
  }

  getCommodities(filters?: CommodityFilters) {
    return this.get<string[]>(CommodityDataServiceConfiguration.AllCommodities);
  }
}

abstract class CommodityDataServiceConfiguration {
  static readonly AllCommodities = new DataServiceConfiguration<string[]>(
      'commodities',
       environment.apiUrl + 'Commodities'
  );
}
