import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { QueryObserverResult } from '@tanstack/query-core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DashboardFilters } from '../../../models/filters/dashboard-filters';
import { RailcarPerformance } from '../../../models/railcar/railcar-performance';
import { RailcarPerformanceCommodity } from '../../../models/railcar/railcar-performance-commodity';
import { RailcarDataService } from '../../../services/railcar-data.service';
import { YearsMonths } from '../../../../shared-rail-performance/components/date-filter/models/years-months';
import { DashboardRailcarFormService } from './services/dashboard-railcar-form.service';
import { SubSink } from 'subsink';
import { DashboardReportFormService } from '../dashboard-report-layout/services/dashboard-report-form.service';
import { DashboardType } from '../../../constants/dashboard-type';
import { RailcarFilters } from '../../../models/filters/railcar-filters';
import { DataExportService } from '../../../../shared-rail-performance/services/data-export/data-export.service';

@Component({
  selector: 'app-dashboard-railcar',
  templateUrl: './dashboard-railcar.component.html',
  styleUrl: './dashboard-railcar.component.scss',
})
export class DashboardRailcarComponent implements OnInit, OnDestroy {
  private sub = new SubSink();

  constructor(public dashboardRailcarFormService: DashboardRailcarFormService,
              private dashboardFormService: DashboardReportFormService) {
    this.sub.sink = this.dashboardFormService.filtersApplied$.subscribe(() => {
      this.dashboardRailcarFormService.querySummaryData();
    });

    this.sub.sink = this.dashboardFormService.exportData$.subscribe(() => {
      this.dashboardRailcarFormService.exportData();
    });
  }

  get performanceQueryResult$(): Observable<QueryObserverResult<RailcarPerformance, Error>> | undefined {
    return this.dashboardRailcarFormService.performanceQueryResult$;
  }

  get performanceCommodityQueryResult$(): Observable<QueryObserverResult<RailcarPerformanceCommodity, Error>> | undefined {
    return this.dashboardRailcarFormService.performanceCommodityQueryResult$;
  }

  get performanceEmptyQueryResult$(): Observable<QueryObserverResult<RailcarPerformance, Error>> | undefined {
    return this.dashboardRailcarFormService.performanceEmptyQueryResult$;
  }

  get performanceLoadedQueryResult$(): Observable<QueryObserverResult<RailcarPerformance, Error>> | undefined {
    return this.dashboardRailcarFormService.performanceLoadedQueryResult$;
  }

  ngOnInit(): void {
    this.dashboardRailcarFormService.querySummaryData();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}