import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DashboardCycleTimeFormService } from './services/dashboard-cycle-time-form.service';

@Component({
  selector: 'app-dashboard-cycle-time',
  templateUrl: './dashboard-cycle-time.component.html',
  styleUrl: './dashboard-cycle-time.component.scss'
})
export class DashboardCycleTimeComponent {
  public filterForm: FormGroup = this.cycleTimeFormService.filterForm;

  constructor(private cycleTimeFormService: DashboardCycleTimeFormService) {

  }
}
