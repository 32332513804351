<div class="map-container d-flex flex-column">
    <div class="map" #mapViewNode [class.invisible]="isMapLoading$ | async"></div>
    <div id="info" style="visibility: hidden;">
        <span id="name"></span><br />
        <span id="dwell-average"></span><br />
    </div>
    <div *ngIf="isMapLoading$ | async"
        class="center-in-chart">
        <div class="spinner-border" role="status">
        </div>
    </div>
    <div *ngIf="isMapError$ | async" class="center-in-chart">
        There was an error loading this data.
    </div>
</div>
