import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CurrentPrevious } from '../../shared-rail-performance/models/current-previous';

@Injectable({
  providedIn: 'root'
})
export class KpiDataService {

  constructor(private http: HttpClient) { }

  getRailcarVolumeKpi() {
    let railcarVolumeKpiUrl: string = environment.apiUrl + 'Kpi/RailcarVolume';
    return this.http.get<CurrentPrevious>(railcarVolumeKpiUrl);
  }

  getNetworkDwellKpi() {
    let networkDwellKpiUrl: string = environment.apiUrl+ 'Kpi/NetworkDwell';
    return this.http.get<CurrentPrevious>(networkDwellKpiUrl);
  }

  getCycleTimeKpi() {
    let cycleTimeKpiUrl: string = environment.apiUrl + 'Kpi/CycleTime';
    return this.http.get<CurrentPrevious>(cycleTimeKpiUrl);
  }
}
