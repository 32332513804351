import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner-loader',
  templateUrl: './spinner-loader.component.html',
  styleUrl: './spinner-loader.component.css',
})
export class SpinnerLoaderComponent {
  @Input() showOverlay: boolean | null = false;
}
