import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardLandingComponent } from "./components/dashboard-landing/dashboard-landing.component";
import { DashboardCycleTimeComponent } from "./components/dashboard-reports/dashboard-cycle-time/dashboard-cycle-time.component";
import { DashboardNetworkDwellComponent } from "./components/dashboard-reports/dashboard-network-dwell/dashboard-network-dwell.component";
import { DashboardRailcarComponent } from "./components/dashboard-reports/dashboard-railcar/dashboard-railcar.component";
import { DashboardReportLayoutComponent } from "./components/dashboard-reports/dashboard-report-layout/components/dashboard-report-layout/dashboard-report-layout.component";
import { DashboardType } from "./constants/dashboard-type";
import { reportResolver } from "./resolvers/report-resolver";

const routes: Routes = [
  {
    path: '',
    component: DashboardLandingComponent,
  },
  {
    path: '',
    component: DashboardReportLayoutComponent,
    children: [
      { 
        path: 'cycle-time', 
        component: DashboardCycleTimeComponent,
        data: {
          dashboardType: DashboardType.CycleTime
        },
        resolve: { reports: reportResolver },
      }, 
      { 
        path: 'network-dwell', 
        component: DashboardNetworkDwellComponent,
        data: {
          dashboardType: DashboardType.NetworkDwell
        },
        resolve: { reports: reportResolver },
      }, 
      { 
        path: 'railcar', 
        component: DashboardRailcarComponent,
        data: {
          dashboardType: DashboardType.RailcarVolume
        },
        resolve: { reports: reportResolver },
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule { }