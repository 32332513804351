<div class="d-flex flex-column h-100 position-relative" [formGroup]="filterForm">
    @if (railcarVolumeHistoryByPeriodQuery$ | async; as result) {
    <div class="d-flex justify-content-end period-chips-container">
        <kendo-chiplist selection="single" (selectedChange)="periodFilterChange($event)">
            <kendo-chip *ngFor="let period of periods; index as i" [selected]="i == 0" rounded="full" fillMode="outline" [label]="period"></kendo-chip>
        </kendo-chiplist>
    </div>
    <app-control-status-indicator [queryObserverResult]="result"></app-control-status-indicator>
    <fusioncharts type="Column2d" [dataSource]="result?.data ?? []" width="100%"></fusioncharts>
    }
</div>