import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CycleTimeByLocationAndMonth } from '../models/cycle-time-average-by-location-and-month';
import { DataQueryService } from '../../shared-rail-performance/services/data/data.service';
import { DataServiceConfiguration } from '../../shared-rail-performance/services/data/data-service-configuration';
import { OperatorFunction } from 'rxjs';
import { DataSource } from '../../shared-rail-performance/models/charts/fusion-charts/data-source';

@Injectable({
  providedIn: 'root'
})
export class CycleDataService extends DataQueryService {
  getCycleTimeAveragesByLocationAndMonthChart(transform: OperatorFunction<CycleTimeByLocationAndMonth[], DataSource>) {
    return this.getWithTransform(CycleDataServiceConfiguration.CycleTimeByLocationAndMonthChart, transform);
  }
}

abstract class CycleDataServiceConfiguration {
  static readonly CycleTimeByLocationAndMonthChart = new DataServiceConfiguration<DataSource>(
      'cycle-time-by-location-and-month',
       environment.apiUrl + 'Cycles'
  );
}