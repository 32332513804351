<div class="d-flex flex-column h-100 position-relative" [formGroup]="filterForm">
    <div class="d-flex justify-content-end period-chips-container pb-2">
        <kendo-chiplist selection="single" (selectedChange)="locationTypeFilterChange($event)">
            <kendo-chip *ngFor="let locationTypeFilter of locationTypeFilters; index as i" [selected]="i == 0"
                rounded="full" fillMode="outline" [label]="locationTypeFilter"></kendo-chip>
        </kendo-chiplist>
    </div>
    <div class="row">
        <div [ngClass]="isMapExpanded ? 'col-12' : 'col'">
            <div class="map-container d-flex flex-column">
                <div class="map" #mapViewNode [class.invisible]="formService.isMapLoading$ | async"></div>
                <div *ngIf="formService.isMapLoading$ | async"
                    class="position-absolute w-50 h-100 d-flex flex-column align-items-center justify-content-center">
                    <div class="spinner-border" role="status">
                    </div>
                </div>
                <app-map-color-gradient-legend [class.invisible]="formService.isMapLoading$ | async" label="Dwell (Days)"
                    [colorVariableStops]="colorVariableStops$ | async"></app-map-color-gradient-legend>
            </div>
        </div>
        <div class="position-relative" [ngClass]="isMapExpanded ? 'col-12' : 'col'">
            @if (stateProvinceQueryResult$ | async; as result;) {
            @if (showStateProvince) {
            <app-control-status-indicator [queryObserverResult]="result"></app-control-status-indicator>
            <kendo-grid #dwellAverageStateProvinceTable *ngIf="!result.isLoading"
            [data]="result?.data?.sort(sortBy('-averageDwell', '-railcarCount')) ?? []" [scrollable]="'scrollable'"
                [pageSize]="10" [height]="520" [rowClass]="stateProvinceRowCallback">
                <kendo-grid-column field="stateProvinceName" title="State/Province" [width]="60"></kendo-grid-column>
                <kendo-grid-column field="railcarCount" title="Count" [width]="60" format="n"></kendo-grid-column>
                <kendo-grid-column field="averageDwell" format="#.00 Days" title="Average Dwell"
                    [width]="60"></kendo-grid-column>
            </kendo-grid>
            }
            }
            @if (stationQueryResult$ | async; as result;) {
            @if (showStation) {
            <app-control-status-indicator [queryObserverResult]="result"></app-control-status-indicator>
            <kendo-grid #dwellAverageStationTable *ngIf="!result.isLoading" [data]="result?.data?.sort(sortBy('-averageDwell', '-railcarCount')) ?? []" [pageSize]="10"
                [height]="520" [rowClass]="stationRowCallback" [scrollable]="'virtual'">
                <kendo-grid-column field="city" title="Station" [width]="60">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        {{dataItem.city}}, {{dataItem.stateProvinceCode}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="railcarCount" title="Count" [width]="60" format="n"></kendo-grid-column>
                <kendo-grid-column field="averageDwell" format="#.00 Days" title="Average Dwell"
                    [width]="60"></kendo-grid-column>
            </kendo-grid>
            }
            }
        </div>
    </div>
</div>