import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class DataExportService {
    createWorkbook(): ExcelJS.Workbook {
        return new ExcelJS.Workbook ();
    }

    addWorksheet(workbook: ExcelJS.Workbook, name: string, data: any[], columnConfiguration?: Partial<ExcelJS.Column>[]): ExcelJS.Worksheet {
        const worksheet = workbook.addWorksheet(name);

        if (!!columnConfiguration) {
            worksheet.columns = columnConfiguration;
        }

        worksheet.addRows(data);

        return worksheet;
    }

    saveWorkbook(workbook: ExcelJS.Workbook, fileName: string): void {       
        // Save the workbook to a blob
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, `${fileName}.xlsx`);
        });
    }
}