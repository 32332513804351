import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { QueryObserverResult } from '@tanstack/query-core';
import { groupBy, map, Observable } from 'rxjs';
import { NetworkDwellDataService } from '../../../../services/network-dwell-data.service';
import { DashboardNetworkDwellFormService } from '../services/dashboard-network-dwell-form.service';
import { NetworkDwellCount } from '../../../../models/network-dwell/network-dwell-count';
import { ChartSeries } from '../../../../../shared-rail-performance/models/charts/fusion-charts/chart-series';
import { Collection } from 'lodash';
import { sortBy } from 'sort-by-typescript';
import { Categories } from '../../../../../shared-rail-performance/models/charts/fusion-charts/categories';
import { DataSource } from '../../../../../shared-rail-performance/models/charts/fusion-charts/data-source';
import { LocationType } from '../../../../constants/location-type';
import { DashboardReportFormService } from '../../dashboard-report-layout/services/dashboard-report-form.service';
import { NetworkDwellFilters } from '../../../../models/filters/network-dwell-filters';
import { DashboardType } from '../../../../constants/dashboard-type';
import { Period } from '../../../../constants/period';
import { ChartSeriesData } from '../../../../../shared-rail-performance/models/charts/fusion-charts/chart-series-data';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-dashboard-dwell-total-chart',
  templateUrl: './dashboard-dwell-total-chart.component.html',
  styleUrl: './dashboard-dwell-total-chart.component.css'
})
export class DashboardDwellTotalChartComponent implements OnInit, OnDestroy {
  private sub = new SubSink();

  constructor(public networkDwellFormService: DashboardNetworkDwellFormService,
              private dashboardFormService: DashboardReportFormService){}

  ngOnInit(): void {
    this.networkDwellFormService.queryTotalChartData();

    this.networkDwellFormService.periodControl.valueChanges.subscribe(() => {
      this.networkDwellFormService.queryTotalChartData();
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  get dwellTotalChartResult$() {
    return this.networkDwellFormService.dwellTotalChartResult$;
  }
}
