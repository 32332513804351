export const environment = {
  production: true,

  // Setup for Cognito, AWS Authentication Toolset
  // https://dev.to/rodrigokamada/authentication-using-the-amazon-cognito-to-an-angular-application-ilh
  // Step 3 (needs to be customized, I've used boilerplate example until I get proper values)
  cognito: {
    userPoolId: 'us-east-2_pptCj2gqV',
    userPoolWebClientId: '1452opnjll0ldmocs201b1oimu',
  },
  fusionChartApiKey: 'MwC4B-11ftE2F3A8C10C7B4C5A3A3F4C1B6B1kzlF5H2eeiubB6C4yoxC2C5A5E7D7D6D5A1H4D3C3A6C7D2C2E1orrC11C2C7bB4E4D4wzmD7E4F4kmA-8D2G1B6D-16acB2E3C1C3H2C8B4A4B1A2B4dnnB14B1a1C6njyD3H4A9hD-17A3LE1A2qqbA4B8E2F5G4F3D3G2C7D5D6B3A1B4x==',
  arcgisApiKey: "AAPKda778d89d71f479f9d01ae44133f5ecelwM1DjR4T2w3SwdUGgjr5lDgHNb9wI1MZApQVSL_-h2YXuW7fn9YCHd-9G6mfFlD",
  //apiUrl: 'api/',
  apiUrl: 'https://railperformanceapi.trinityrailmarket.com/',
  //apiUrl: 'https://1352-31000-600020-railperformanceapi-trin-net.azurewebsites.net/'
  //apiUrl: 'http://txteddi2iapi01.prod.ted.loc:82/api/',
};
