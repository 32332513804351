import { Injectable } from '@angular/core';
import { RailcarVolumeHistory } from '../models/railcar/railcar-volume-history';
import { DashboardFilters } from '../models/filters/dashboard-filters';
import { RailcarFilters } from '../models/filters/railcar-filters';
import { DataSource } from '../../shared-rail-performance/models/charts/fusion-charts/data-source';
import { OperatorFunction } from 'rxjs';
import { DataQueryService } from '../../shared-rail-performance/services/data/data.service';
import { environment } from '../../../../environments/environment';
import { DataServiceConfiguration } from '../../shared-rail-performance/services/data/data-service-configuration';
import { RailcarPerformance } from '../models/railcar/railcar-performance';
import { RailcarPerformanceCommodity } from '../models/railcar/railcar-performance-commodity';
import { RailcarVolumeHistoryByPeriod } from '../models/railcar/railcar-volume-history-by-period';
import { RailcarVolumeHistoryByWeekStation } from '../components/dashboard-reports/dashboard-railcar/dashboard-railcar-table/models/railcar-volume-history-by-week-station';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { PagedResult } from '../../shared-rail-performance/models/paging/paged-result';
import { RailcarVolumeHistoryByWeekStateProvince } from '../components/dashboard-reports/dashboard-railcar/dashboard-railcar-table/models/railcar-volume-history-by-week-state-province';
import { LocationType } from '../constants/location-type';

@Injectable({
  providedIn: 'root'
})
export class RailcarDataService extends DataQueryService {
  getRailcarVolumeHistoryWithAverage(filters?: RailcarFilters) {
    return this.post(RailcarDataServiceConfiguration.RailcarVolumeHistoryWithAverage, filters);
  }

  getRailcarVolumeHistoryWithAverageChartData(filters: RailcarFilters, transform: OperatorFunction<RailcarVolumeHistory[], DataSource>) {
    return this.postWithTransform(RailcarDataServiceConfiguration.RailcarVolumeWithAverageHistoryChart, transform, filters);
  }

  getRailcarVolumeHistoryByPeriod(filters: RailcarFilters) {
    return this.post(RailcarDataServiceConfiguration.RailcarVolumeByPayPeriod, filters);
  }

  getRailcarVolumeHistoryByPeriodChartData(filters: RailcarFilters, transform: OperatorFunction<RailcarVolumeHistoryByPeriod[], DataSource>) {
    return this.postWithTransform(RailcarDataServiceConfiguration.RailcarVolumeByPayPeriod, transform, filters);
  }

  getRailcarVolumeHistoryByWeekLocation(filters: RailcarFilters) {
    if (filters.locationType == LocationType.StateProvince) {
      return this.post(RailcarDataServiceConfiguration.RailcarVolumeHistoryByWeekStateProvince, filters);
    }
    else {
      return this.post(RailcarDataServiceConfiguration.RailcarVolumeHistoryByWeekStation, filters);
    }
  }

  getRailcarVolumeHistoryByWeekLocationTableData(filters: RailcarFilters, transform: OperatorFunction<RailcarVolumeHistoryByWeekStation[], GridDataResult>) {
    if (filters.locationType === LocationType.StateProvince) {
      return this.postWithTransform(RailcarDataServiceConfiguration.RailcarVolumeHistoryByWeekStateProvince, transform, filters);
    }
    else {
      return this.postWithTransform(RailcarDataServiceConfiguration.RailcarVolumeHistoryByWeekStation, transform, filters);
    }
  }

  getRailcarPerformance(filters?: DashboardFilters) {
    return this.post(RailcarDataServiceConfiguration.RailcarPerformance, filters);
  }

  getRailcarPerformanceCommodity(filters?: DashboardFilters) {
    return this.post(RailcarDataServiceConfiguration.RailcarPerformanceCommodity, filters);
  }

  getRailcarPerformanceEmpty(filters?: DashboardFilters) {
    return this.post(RailcarDataServiceConfiguration.RailcarPerformanceEmpty, filters);
  }

  getRailcarPerformanceLoaded(filters?: DashboardFilters) {
    return this.post(RailcarDataServiceConfiguration.RailcarPerformanceLoaded, filters);
  }
}

class RailcarDataServiceConfiguration {
  static readonly RailcarVolumeHistoryWithAverage = new DataServiceConfiguration<RailcarVolumeHistory>(
      'railcar-volume-history-average',
       environment.apiUrl + 'RailcarVolume/History/Average'
  );

  static readonly RailcarVolumeWithAverageHistoryChart = new DataServiceConfiguration<RailcarVolumeHistory>(
      'railcar-volume-history-average-chart',
      this.RailcarVolumeHistoryWithAverage.url,
  );

  static readonly RailcarVolumeByPayPeriod = new DataServiceConfiguration<RailcarVolumeHistoryByPeriod[]>( 
      'railcar-volume-history-by-period',
      environment.apiUrl + 'RailcarVolume/History/Period'
  );

  static readonly RailcarVolumeHistoryByWeekStateProvince = new DataServiceConfiguration<RailcarVolumeHistoryByWeekStateProvince>( 
    'railcar-volume-history-by-week-state-province',
    environment.apiUrl + 'RailcarVolume/History/Week/StateProvince'
  );

  static readonly RailcarVolumeHistoryByWeekStation = new DataServiceConfiguration<RailcarVolumeHistoryByWeekStation>( 
    'railcar-volume-history-by-week-station',
    environment.apiUrl + 'RailcarVolume/History/Week/Station'
  );

  static readonly RailcarPerformance = new DataServiceConfiguration<RailcarPerformance>(
      'railcar-performance',
      environment.apiUrl + 'RailcarPerformance'
  );

  static readonly RailcarPerformanceCommodity = new DataServiceConfiguration<RailcarPerformanceCommodity>(
      'railcar-performance-commodity',
      environment.apiUrl + 'RailcarPerformance/Commodity'
  );

  static readonly RailcarPerformanceEmpty = new DataServiceConfiguration<RailcarPerformance>(
      'railcar-performance-empty',
      environment.apiUrl + 'RailcarPerformance/Empty'
  );

  static readonly RailcarPerformanceLoaded = new DataServiceConfiguration<RailcarPerformance>(
      'railcar-performance-loaded',
      environment.apiUrl + 'RailcarPerformance/Loaded'
  );
}