import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, map, merge, Observable, startWith } from 'rxjs';
import { ChartSeriesData } from '../../../../../shared-rail-performance/models/charts/fusion-charts/chart-series-data';
import { RailcarVolumeHistoryByPeriod } from '../../../../models/railcar/railcar-volume-history-by-period';
import { RailcarDataService } from '../../../../services/railcar-data.service';
import { DataSource } from '../../../../../shared-rail-performance/models/charts/fusion-charts/data-source';
import { QueryObserverResult } from '@tanstack/query-core';
import { RailcarPerformance } from '../../../../models/railcar/railcar-performance';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Form, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DashboardFilters } from '../../../../models/filters/dashboard-filters';
import { RailcarFilters } from '../../../../models/filters/railcar-filters';
import { Months } from '../../../../../shared-rail-performance/data/months';
import { Period, Periods } from '../../../../constants/period';
import { Result } from '@ngneat/query/lib/types';
import { DashboardRailcarFormService } from '../services/dashboard-railcar-form.service';
import { sortBy } from 'sort-by-typescript';
import { SubSink } from 'subsink';
import { DashboardReportFormService } from '../../dashboard-report-layout/services/dashboard-report-form.service';
import { DashboardType } from '../../../../constants/dashboard-type';

@Component({
  selector: 'app-dashboard-railcar-graph',
  templateUrl: './dashboard-railcar-graph.component.html',
  styleUrl: './dashboard-railcar-graph.component.css',
})
export class DashboardRailcarGraphComponent implements OnInit, OnDestroy {
  faCaretDown = faCaretDown;
  public periods = Periods;
  public filterForm = this.formService.filterForm;

  private sub = new SubSink();

  constructor(private railcarDataService: RailcarDataService,
    private formService: DashboardRailcarFormService,
    private dashboardFormService: DashboardReportFormService
  ) {
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.formService.queryGraphData();

    this.sub.sink = combineLatest([
      this.formService.filterForm.valueChanges.pipe(startWith({})), 
      this.dashboardFormService.filtersApplied$.pipe(startWith(undefined as void))]).subscribe(() => {
        this.formService.queryGraphData();
    });
  } 

  get railcarVolumeHistoryByPeriodQuery$() {
    return this.formService.railcarVolumeHistoryByPeriodQuery$;
  }

  periodFilterChange(event: any) {
    let periodIndex = event[0];
    let period = Periods[periodIndex];

    if (period == Period.Annually || period == Period.Quarterly) {
      this.formService.monthsFormControl.setValue([]);
      this.formService.monthsFormControl.disable();
    }
    else {
      this.formService.monthsFormControl.enable();
    }

    this.formService.periodFormControl.setValue(period);
  }
}
