import { NgModule } from "@angular/core";
import { PerformanceMetricComponent } from "./components/performance-metric/performance-metric.component";
import { EllipsisLoaderComponent } from "./components/ellipsis-loader/ellipsis-loader.component";
import { CommonModule } from "@angular/common";
import { MapColorGradientLegendComponent } from "./components/map-color-gradient-legend/map-color-gradient-legend.component";
import { DateFilterComponent } from "./components/date-filter/date-filter.component";
import { COMMON_KENDO_IMPORTS } from "../../common/common-imports";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ControlStatusIndicatorComponent } from "./components/control-status-indicator/control-status-indicator.component";
import { SpinnerLoaderComponent } from "./components/spinner-loader/spinner-loader.component";

@NgModule({
  imports: [
    CommonModule,
    COMMON_KENDO_IMPORTS,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    EllipsisLoaderComponent,
    PerformanceMetricComponent,
    MapColorGradientLegendComponent,
    DateFilterComponent,
    ControlStatusIndicatorComponent,
    SpinnerLoaderComponent
  ],
  declarations: [
    EllipsisLoaderComponent,
    PerformanceMetricComponent,
    MapColorGradientLegendComponent,
    DateFilterComponent,
    ControlStatusIndicatorComponent,
    SpinnerLoaderComponent
  ],
  schemas: [],
})
export class SharedRailPerformanceModule { }
