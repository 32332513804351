import { Component, OnDestroy, OnInit } from "@angular/core";
import { QueryObserverResult } from "@tanstack/query-core";
import { Observable, BehaviorSubject } from "rxjs";
import { Country } from "../../../../shared-rail-performance/models/location/country";
import { StateProvince } from "../../../../shared-rail-performance/models/location/state-province";
import { CommodityDataService } from "../../../../shared-rail-performance/services/commodity-data.service";
import { CustomerDataService } from "../../../../shared-rail-performance/services/customer-data.service";
import { LocationDataService } from "../../../../shared-rail-performance/services/location-data.service";
import { DashboardFilters } from "../../../models/filters/dashboard-filters";
import { NetworkDwellCommodity } from "../../../models/network-dwell/network-dwell-commodity";
import { NetworkDwellHistorical } from "../../../models/network-dwell/network-dwell-historical";
import { NetworkDwellLocation } from "../../../models/network-dwell/network-dwell-location";
import { NetworkDwellPerformance } from "../../../models/network-dwell/network-dwell-performance";
import { NetworkDwellStation } from "../../../models/network-dwell/network-dwell-station";
import { NetworkDwellDataService } from "../../../services/network-dwell-data.service";
import { Months } from "../../../../shared-rail-performance/data/months";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Period, Periods } from "../../../constants/period";
import { LocationType } from "../../../constants/location-type";
import { DashboardNetworkDwellFormService } from "./services/dashboard-network-dwell-form.service";
import { SubSink } from "subsink";
import { DashboardReportFormService } from "../dashboard-report-layout/services/dashboard-report-form.service";
import { NetworkDwellFilters } from "../../../models/filters/network-dwell-filters";
import { DashboardType } from "../../../constants/dashboard-type";

@Component({
  selector: 'app-dashboard-network-dwell',
  templateUrl: './dashboard-network-dwell.component.html',
  styleUrl: './dashboard-network-dwell.component.scss',
})
export class DashboardNetworkDwellComponent implements OnInit, OnDestroy {
  public filterForm: FormGroup = this.networkDwellFormService.filterForm;

  private sub = new SubSink();

  public locations = [LocationType.StateProvince, LocationType.Region, LocationType.Country];
  public periods = Periods;

  constructor(private networkDwellFormService: DashboardNetworkDwellFormService,
              private dashboardFormService: DashboardReportFormService) {   
  }

  ngOnInit(): void {
    this.networkDwellFormService.querySummaryData();

    this.sub.sink = this.dashboardFormService.filtersApplied$.subscribe(() => {
      this.networkDwellFormService.querySummaryData();
    });

    this.sub.sink = this.dashboardFormService.exportData$.subscribe(() => {
      this.networkDwellFormService.exportData();
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  locationChange(event: any) {
    let locationIndex = event[0];
    let location = this.locations[locationIndex];

    this.networkDwellFormService.graphLocationTypeControl.setValue(location);
  }

  periodFilterChange(event: any) {
    let periodIndex = event[0];
    let period = Periods[periodIndex];

    this.networkDwellFormService.periodControl.setValue(period);
  }

  get dwellSummaryQueryResult$(): Observable<QueryObserverResult<NetworkDwellPerformance, Error>> | undefined {
    return this.networkDwellFormService.dwellSummaryQueryResult$;
  }

  get dwellLocationQueryResult$(): Observable<QueryObserverResult<NetworkDwellLocation, Error>> | undefined {
    return this.networkDwellFormService.dwellLocationQueryResult$;
  }

  get dwellCommodityQueryResult$(): Observable<QueryObserverResult<NetworkDwellCommodity, Error>> | undefined {
    return this.networkDwellFormService.dwellCommodityQueryResult$;
  }

  get dwellStationQueryResult$(): Observable<QueryObserverResult<NetworkDwellStation, Error>> | undefined {
    return this.networkDwellFormService.dwellStationQueryResult$;
  }
}