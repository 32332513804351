<div class="content-width">
  <kendo-tabstrip class="mt-5">
    <kendo-tabstrip-tab title="CYCLES" [selected]="true">
      <ng-template kendoTabContent>
        <div class="dashboard-cards">
          <div class="dashboard">
            <div class="dashboard-title">
              Historical Performance
            </div>
            <div class="dashboard-divider"></div>
            <div class="dashboard-subtitle">
              Historical cycle time performance metrics.
            </div>
            <app-date-filter [startYear]="2021" [formGroup]="filterForm" yearsFormControlName="years"
              monthsFormControlName="months"></app-date-filter>
            <div class="performance_cards">
              <div class="performance_card">
                <div class="performance_title">
                  <svg width="36" height="20" viewBox="0 0 36 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="3" y="4.92871" width="28.83" height="9.32895" rx="4.66447" fill="#287DA1" />
                    <path d="M15.197 4.35742L18.9207 4.35742" stroke="#287DA1" stroke-width="0.672516"
                      stroke-linecap="round" />
                    <ellipse cx="1.24121" cy="1.41853" rx="1.24121" ry="1.41853"
                      transform="matrix(-1 0 0 1 28.0505 15.1299)" fill="#287DA1" />
                    <ellipse cx="1.24121" cy="1.41853" rx="1.24121" ry="1.41853"
                      transform="matrix(-1 0 0 1 30.5325 15.1299)" fill="#287DA1" />
                    <rect x="26.1887" y="15.1299" width="3.72364" height="2.12779" fill="#287DA1" />
                    <ellipse cx="1.24121" cy="1.41853" rx="1.24121" ry="1.41853"
                      transform="matrix(-1 0 0 1 6.59131 15.1328)" fill="#287DA1" />
                    <ellipse cx="1.24121" cy="1.41853" rx="1.24121" ry="1.41853"
                      transform="matrix(-1 0 0 1 9.07373 15.1328)" fill="#287DA1" />
                    <rect x="4.72925" y="15.1328" width="3.72364" height="2.12779" fill="#287DA1" />
                    <path d="M4.10889 15.1299L30.7212 15.1299" stroke="#287DA1" stroke-width="0.736305"
                      stroke-linecap="round" />
                  </svg>
                  Railcar Volume
                </div>
                <div class="performance_metric">
                  <div class="performance_value">
                    <app-ellipsis-loader></app-ellipsis-loader>
                    <div></div>
                  </div>
                  <div class="performance_uom">Railcars</div>
                </div>
                <div class="performance_compare">
                </div>
              </div>
              <div class="performance_card">
                <div class="performance_title">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.0001 1H10.0001C9.4501 1 9.0001 1.45 9.0001 2C9.0001 2.55 9.4501 3 10.0001 3H14.0001C14.5501 3 15.0001 2.55 15.0001 2C15.0001 1.45 14.5501 1 14.0001 1ZM12.0001 14C12.5501 14 13.0001 13.55 13.0001 13V9C13.0001 8.45 12.5501 8 12.0001 8C11.4501 8 11.0001 8.45 11.0001 9V13C11.0001 13.55 11.4501 14 12.0001 14ZM19.0301 7.39L19.7801 6.64C20.1601 6.26 20.1701 5.63 19.7801 5.24L19.7701 5.23C19.3801 4.84 18.7601 4.85 18.3701 5.23L17.6201 5.98C16.0701 4.74 14.1201 4 12.0001 4C7.2001 4 3.1201 7.96 3.0001 12.76C2.8701 17.84 6.9401 22 12.0001 22C16.9801 22 21.0001 17.97 21.0001 13C21.0001 10.88 20.2601 8.93 19.0301 7.39ZM12.0001 20C8.1301 20 5.0001 16.87 5.0001 13C5.0001 9.13 8.1301 6 12.0001 6C15.8701 6 19.0001 9.13 19.0001 13C19.0001 16.87 15.8701 20 12.0001 20Z"
                      fill="#287DA1" />
                  </svg>
                  Average Dwell
                </div>
                <div class="performance_metric">
                  <div class="performance_value">
                    <app-ellipsis-loader></app-ellipsis-loader>
                    <div></div>
                  </div>
                  <div class="performance_uom">Days</div>
                </div>
                <div class="performance_compare">
                </div>
              </div>
              <div class="performance_card">
                <div class="performance_title">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.07 5.32003C16.26 6.00003 18 7.74003 18.68 9.93003C18.87 10.56 19.44 11 20.09 11H20.13C21.13 11 21.85 10.04 21.56 9.09003C20.59 5.91003 18.08 3.40003 14.9 2.43003C13.96 2.14003 13 2.86003 13 3.86003V3.90003C13 4.56003 13.44 5.13003 14.07 5.32003ZM18.68 14.07C18 16.27 16.26 18 14.07 18.68C13.44 18.87 13 19.44 13 20.09V20.13C13 21.13 13.96 21.85 14.91 21.56C18.09 20.59 20.6 18.08 21.57 14.9C21.86 13.95 21.14 12.99 20.15 12.99H20.1C19.44 13 18.87 13.44 18.68 14.07ZM11 20.11C11 19.44 10.55 18.87 9.91 18.67C7.07 17.78 5 15.13 5 12C5 8.87003 7.07 6.22003 9.91 5.33003C10.55 5.13003 11 4.56003 11 3.89003V3.88003C11 2.88003 10.03 2.14003 9.07 2.44003C4.98 3.69003 2 7.50003 2 12C2 16.5 4.98 20.31 9.07 21.56C10.03 21.86 11 21.12 11 20.11Z"
                      fill="#287DA1" />
                  </svg>
                  Cycle Time
                </div>
                <div class="performance_metric">
                  <div class="performance_value">
                    <app-ellipsis-loader></app-ellipsis-loader>
                  </div>
                  <div class="performance_uom">Days</div>
                </div>
                <div class="performance_compare">
                </div>
              </div>
              <div class="performance_card">
                <div class="performance_title">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.47012 20.9997H19.5301C21.0701 20.9997 22.0301 19.3297 21.2601 17.9997L13.7301 4.98969C12.9601 3.65969 11.0401 3.65969 10.2701 4.98969L2.74012 17.9997C1.97012 19.3297 2.93012 20.9997 4.47012 20.9997ZM12.0001 13.9997C11.4501 13.9997 11.0001 13.5497 11.0001 12.9997V10.9997C11.0001 10.4497 11.4501 9.99969 12.0001 9.99969C12.5501 9.99969 13.0001 10.4497 13.0001 10.9997V12.9997C13.0001 13.5497 12.5501 13.9997 12.0001 13.9997ZM13.0001 17.9997H11.0001V15.9997H13.0001V17.9997Z"
                      fill="#287DA1" />
                  </svg>
                  TBD (future)
                </div>
                <div class="performance_metric">
                  <div class="performance_value">TBD</div>
                  <div class="performance_uom">Railcars</div>
                </div>
                <div class="performance_compare">
                  <div innerHTML="TBD"></div>
                </div>
              </div>
            </div>
            <div class="area-container">
              <div class="dashboard-title">
                Average Cycle Time
              </div>
              <div class="muted">
                Historical cycle times for origin/destination pairs.
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="TRIPS">
      <ng-template kendoTabContent>

      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>