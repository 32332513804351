<div class="dashboard">
  <div class="dashboard-title">
    Active Performance
  </div>
  <div class="dashboard-divider"></div>
  <div class="dashboard-subtitle">
    Current Railcar Count Metrics Based On Latest Railcar Messages
  </div>
  <div class="performance_cards">
    <div class="performance_card">
      <div class="performance_title">
        <img src="/assets/icons_reports/report_locationmarker_icon.svg" /> Railcar Count
      </div>
      @if (performanceQueryResult$ | async; as result) {
      <app-performance-metric label="Total" [value]="result.data?.railcarTotal" unitOfMeasure="Railcars"
        [isLoading]="result.isLoading"></app-performance-metric>
      <app-performance-metric label="In Motion" [value]="result.data?.railcarMotion" unitOfMeasure="Railcars"
        [isLoading]="result.isLoading"></app-performance-metric>
      <app-performance-metric label="At Origin" [value]="result.data?.railcarOrigin" unitOfMeasure="Railcars"
        [isLoading]="result.isLoading"></app-performance-metric>
      <app-performance-metric label="At Destination" [value]="result.data?.railcarDestination" unitOfMeasure="Railcars"
        [isLoading]="result.isLoading"></app-performance-metric>
      }
    </div>
    <div class="performance_card">
      <div class="performance_title">
        <img src="/assets/icons_reports/report_commoditieschecklist_icon.svg" /> Commodity Count
      </div>
      @if (performanceCommodityQueryResult$ | async; as result) {
      <app-performance-metric [label]="result.data?.commodity1Type" [value]="result.data?.commodity1Cars"
        unitOfMeasure="Railcars" [showIfZero]="false" [isLoading]="result.isLoading"></app-performance-metric>
      <app-performance-metric [label]="result.data?.commodity2Type" [value]="result.data?.commodity2Cars"
        unitOfMeasure="Railcars" [showIfZero]="false" [isLoading]="result.isLoading"></app-performance-metric>
      <app-performance-metric [label]="result.data?.commodity3Type" [value]="result.data?.commodity3Cars"
        unitOfMeasure="Railcars" [showIfZero]="false" [isLoading]="result.isLoading"></app-performance-metric>
      <app-performance-metric [label]="result.data?.commodity4Type" [value]="result.data?.commodity4Cars"
        unitOfMeasure="Railcars" [isLoading]="result.isLoading"></app-performance-metric>
      }
    </div>
    <div class="performance_card">
      <div class="performance_title">
        <img src="/assets/icons_reports/report_empty_icon.svg" /> Empty Railcars
      </div>
      @if (performanceEmptyQueryResult$ | async; as result) {
      <app-performance-metric label="Total" [value]="result.data?.railcarTotal" unitOfMeasure="Railcars"
        [isLoading]="result.isLoading"></app-performance-metric>
      <app-performance-metric label="In Motion" [value]="result.data?.railcarMotion" unitOfMeasure="Railcars"
        [isLoading]="result.isLoading"></app-performance-metric>
      <app-performance-metric label="At Origin" [value]="result.data?.railcarOrigin" unitOfMeasure="Railcars"
        [isLoading]="result.isLoading"></app-performance-metric>
      <app-performance-metric label="At Destination" [value]="result.data?.railcarDestination" unitOfMeasure="Railcars"
        [isLoading]="result.isLoading"></app-performance-metric>
      }
    </div>
    <div class="performance_card">
      <div class="performance_title">
        <img src="/assets/icons_reports/report_loaded_icon.svg" /> Loaded Railcars
      </div>
      @if (performanceLoadedQueryResult$ | async; as result) {
      <app-performance-metric label="Total" [value]="result.data?.railcarTotal" unitOfMeasure="Railcars"
        [isLoading]="result.isLoading"></app-performance-metric>
      <app-performance-metric label="In Motion" [value]="result.data?.railcarMotion" unitOfMeasure="Railcars"
        [isLoading]="result.isLoading"></app-performance-metric>
      <app-performance-metric label="At Origin" [value]="result.data?.railcarOrigin" unitOfMeasure="Railcars"
        [isLoading]="result.isLoading"></app-performance-metric>
      <app-performance-metric label="At Destination" [value]="result.data?.railcarDestination" unitOfMeasure="Railcars"
        [isLoading]="result.isLoading"></app-performance-metric>
      }
    </div>
  </div>
</div>
<div class="content-width">
  <div class="content-width dashboard-cards">
    <div class="dashboard">
      <div class="dashboard-title">
        Historical Performance
      </div>
      <div class="dashboard-divider"></div>
      <div class="muted">
        Historical Railcar Count Metrics
      </div>
      <app-date-filter [startYear]="2021" [formGroup]="dashboardRailcarFormService.filterForm" yearsFormControlName="years"
        monthsFormControlName="months"></app-date-filter>
      <app-dashboard-railcar-graph></app-dashboard-railcar-graph>
      <app-dashboard-railcar-table></app-dashboard-railcar-table>
    </div>
  </div>
</div>