import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MapService } from '../../../../../shared-rail-performance/services/map-service';
import { NetworkDwellAverageByLocationMapService } from './network-dwell-average-by-location-map.service';
import MapView from '@arcgis/core/views/MapView';
import { QueryObserverResult } from '@tanstack/query-core';
import { BehaviorSubject, filter, from, map, mergeMap, Observable } from 'rxjs';
import { NetworkDwellAverageByStateProvince } from '../../../../models/network-dwell/network-dwell-average-by-state-province';
import { NetworkDwellDataService } from '../../../../services/network-dwell-data.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-network-dwell-average-by-location-map',
  templateUrl: './network-dwell-average-by-location-map.component.html',
  styleUrl: './network-dwell-average-by-location-map.component.css',
})
export class NetworkDwellAverageByLocationMapComponent implements OnInit, OnDestroy {
  public isMapLoading$ = new BehaviorSubject<boolean>(true);
  public isMapError$ = new BehaviorSubject<boolean>(false);
  public queryResult$?: Observable<QueryObserverResult<NetworkDwellAverageByStateProvince[], Error>>;

  @ViewChild('mapViewNode', { static: true }) private mapViewEl!: ElementRef;

  private mapView?: MapView;

  private sub = new SubSink();

  constructor(private mapService: NetworkDwellAverageByLocationMapService, private networkDwellDataService: NetworkDwellDataService) {
    this.queryResult$ = this.networkDwellDataService.getNetworkDwellAveragesByStateProvinceLastMonth().result$;
  }

  ngOnInit(): any {
    this.sub.sink = this.queryResult$?.pipe(mergeMap((results) => {
      return from(this.mapService.load(this.mapViewEl, results.data ?? []).then((mapView) => {
        this.mapView = mapView;
      })).pipe(map(() => {
        return results;
      }))
    }), filter((results) => {
      return !results.isLoading;
    })).subscribe({
      next: x => { this.isMapLoading$.next(false); this.isMapError$.next(false); },
      error: x => { this.isMapLoading$.next(false); this.isMapError$.next(true); }
    });
  }

  ngOnDestroy(): void {
    if (this.mapView) {
      this.mapView.destroy();
    }

    this.sub.unsubscribe();
  }
  // public isNetworkDwellMapLoading$ = new BehaviorSubject<boolean>(false);
  // public railcarVolumesGraphData?: any;
  // public railcarVolumesGraphOptions?: any;

  // public dataSource: Object = {};

  // @ViewChild('networkDwellCanvas') networkDwellCanvas: ElementRef<HTMLCanvasElement> | null = null;

  // constructor(private networkDwellDataService: NetworkDwellDataService) {
  //   defineCustomElements(window, { resourcesUrl: "https://js.arcgis.com/map-components/4.30/assets" });

  //   this.isNetworkDwellMapLoading$.next(true);

  //   this.loadNetworkDwellAverageByLocation();
  // }

  // private get states10mMapData() {
  //   return States10mMapData as any;
  // }

  // loadNetworkDwellAverageByLocation() {
  //   this.networkDwellDataService.getNetworkDwellAveragesByStateProvince({}).subscribe({ next: dataset => { 
  //     //this.drawNetworkDwellAverageByLocation(dataset);
  //     this.isNetworkDwellMapLoading$.next(false);
  //   }});
  // }

  // drawNetworkDwellAverageByLocation(networkDwellData: NetworkDwellAverageByStateProvince[]) {
  //   if (networkDwellData == null || networkDwellData.length == 0) return;
  //   let map_scale: number = 1.6;
  //   Chart.register(ChartGeo.ChoroplethController);
  //   Chart.register(ChartGeo.ProjectionScale);
  //   Chart.register(ChartGeo.ColorScale);
  //   Chart.register(ChartGeo.GeoFeature);

  //   let geo_objects: any = ChartGeo.topojson.feature(this.states10mMapData, this.states10mMapData.objects.states);
  //   //let nation = geo_objects.features[0];
  //   let states = geo_objects.features;

  //   const chart = new Chart(this.networkDwellCanvas!.nativeElement.getContext("2d")!,{
  //     type: 'choropleth',
  //     data: {
  //       labels: states!.map((d: any) => d.properties.name),
  //       datasets: [{
  //         label: 'States',
  //         //outline: nation,
  //         //data: chartdata,
  //         data: states!.map((d: any) => ({ feature: d, value: NetworkDwell_Formatting.getNetworkDwellAverageByLocation(networkDwellData, d.properties.name) }))
  //         //data: states!.map((d: any) => ({ feature: d, value: Math.random() * 10 })),
  //       }]
  //     },
  //     options: {
  //       maintainAspectRatio: true,
  //       aspectRatio: 1.6,
  //       plugins: { legend: { display: false }, },
  //       scales: {
  //         projection: {
  //           axis: 'x',
  //           projection: 'albersUsa'
  //         },
  //         color: {
  //           axis: 'x',
  //           quantize: 5,
  //           legend: { position: 'bottom-right', align: 'bottom' },
  //         }
  //       },
  //     }
  //   });
  // }
}
