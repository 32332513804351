<div class="d-flex flex-column h-100 position-relative">
    @if (railcarVolumeHistoryByWeekLocationQueryResult$ | async; as dataResult) {
    <div class="d-flex justify-content-end period-chips-container">
        <kendo-chiplist selection="single" (selectedChange)="locationTypeChange($event)">
            <kendo-chip *ngFor="let locationType of locationTypes; index as i" [selected]="i == 0" rounded="full" fillMode="outline"
                [label]="locationType"></kendo-chip>
        </kendo-chiplist>
    </div>
    <app-control-status-indicator [queryObserverResult]="dataResult"></app-control-status-indicator>
    <kendo-grid [data]="dataResult.data!"
        [height]="400" (pageChange)="pageChange($event)" [skip]="formService.pagingState$.value.skip" [pageSize]="formService.pagingState$.value.take"
        [pageable]="true">
        <kendo-grid-column field="year" title="Year" [width]="60"></kendo-grid-column>
        <kendo-grid-column title="Week" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem.weekStartDate | date:'MMM dd' }} - {{ addDays(dataItem.weekStartDate, 6) | date:'MMM dd' }}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="total" title="Total" [width]="100" format="n"></kendo-grid-column>
        <kendo-grid-column *ngFor="let column of formService.columnList" [title]="column.title"
            [width]="getColumnWidth(column)" [field]="column.field" format="n"></kendo-grid-column>
        <kendo-grid-messages noRecords="  ">
        </kendo-grid-messages>
    </kendo-grid>
    }
</div>