import { LocationType } from "../../constants/location-type";
import { DashboardFilters } from "./dashboard-filters";

export class RailcarFilters extends DashboardFilters {
  motion?: string[] = [];

  startDate?: Date;

  locationType?: string = LocationType.StateProvince;
  months?: number[];
  years?: number[];
  period?: string;
  take?: number;
  skip?: number;
  page?: number;
  pageSize?: number;

  static setPaging(filters: RailcarFilters, take: number, skip: number) {
    filters.take = take;
    filters.skip = skip;

    filters.page = (skip / take) + 1;
    filters.pageSize = take;
  }
}
