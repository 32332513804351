import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CurrentPrevious } from '../../../../../shared-rail-performance/models/current-previous';
import { KpiDataService } from '../../../../services/kpi-data.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-kpi-metrics',
  templateUrl: './kpi-metrics.component.html',
  styleUrl: './kpi-metrics.component.scss',
})
export class KpiMetricsComponent implements OnInit, OnDestroy {
  railcarVolume: string = "0";
  railcarVolumeChange: string = "0";

  averageDwell: string = "0";
  averageDwellChange: string = "0";

  cycleTime: string = "0";
  cycleTimeChange: string = "0";

  private sub = new SubSink();

  isRailcarVolumeLoading$ = new BehaviorSubject<boolean>(true);
  isAverageDwellLoading$ = new BehaviorSubject<boolean>(true);
  isCycleTimeLoading$ = new BehaviorSubject<boolean>(true);

  constructor(private kpiDataService: KpiDataService) {
    
  }

  ngOnInit(): void {
    // KPI #1 - Railcar Volume
    this.sub.sink = this.kpiDataService.getRailcarVolumeKpi().subscribe((railcarVolumeKpi: CurrentPrevious) => {
      this.loadRailcarKpi(railcarVolumeKpi);
      this.isRailcarVolumeLoading$.next(false);
    });

    // KPI #2 - Average Network Dwell
    this.sub.sink = this.kpiDataService.getNetworkDwellKpi().subscribe((dwellKpi: CurrentPrevious) => { 
      this.loadAverageDwellKpi(dwellKpi);
      this.isAverageDwellLoading$.next(false);
    });

    // KPI #3 - Average Cycle Time
    this.sub.sink = this.kpiDataService.getCycleTimeKpi().subscribe((cycleCpi: CurrentPrevious) => { 
      this.loadCycleTimeKpi(cycleCpi);
      this.isCycleTimeLoading$.next(false);
    });

    // KPI #4 - TBD Not implemented.
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  loadRailcarKpi(dataset: CurrentPrevious | null) {
    if (dataset == null) return;
    this.railcarVolume = dataset.current.toFixed(0).toString();
    this.railcarVolumeChange = this.getChangePercentText(dataset);
  }

  loadAverageDwellKpi(dataset: CurrentPrevious | null) {
    if (dataset == null) return;
    this.averageDwell = dataset.current.toFixed(2).toString();
    this.averageDwellChange = this.getChangePercentText(dataset);
  }

  loadCycleTimeKpi(dataset: CurrentPrevious | null) {
    if (dataset == null) return;
    this.cycleTime = dataset.current.toFixed(2).toString();
    this.cycleTimeChange = this.getChangePercentText(dataset);
  }

  getChangePercentText(dataset: CurrentPrevious) {
    if (!dataset.previous || dataset.previous === 0) {
      return '';
    }

    let change = Math.round(((100 * (dataset.current - dataset.previous)) / dataset.previous));

    if (change > 0) { 
      return `<img src=\"/assets/icons_reports/dashboard_uptrend.svg\"> &nbsp; up ${Math.abs(change).toFixed(0)}% since last month`; 
    }
    else if (change == 0) { 
      return `no change since last month`; 
    }
    else  { 
      return `<img src=\"/assets/icons_reports/dashboard_downtrend.svg\"> &nbsp; down ${Math.abs(change).toFixed(0)}% since last month`;
    }
  }
}
