import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocationFilters } from '../../models/filters/location-filters';
import { environment } from '../../../../../environments/environment';
import { injectQuery, injectQueryClient } from '@ngneat/query';
import { DataServiceConfiguration } from './data-service-configuration';
import { concat, concatMap, defer, EMPTY, expand, from, map, mergeMap, Observable, of, OperatorFunction, toArray } from 'rxjs';
import { QueryFunctionWithObservable } from '@ngneat/query/lib/base-query';
import { PagedResult } from '../../models/paging/paged-result';

@Injectable({
    providedIn: 'root'
})
export abstract class DataQueryService {
    private readonly query = injectQuery();
    private readonly queryClient = injectQueryClient();

    constructor(private http: HttpClient) { }

    prefetch(): Observable<any> { 
        return of(void 0);
    }

    get<T, Y extends { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } | undefined = undefined>(dataServiceConfiguration: DataServiceConfiguration<T>, parameters?: Y) {
        return this.query({
            queryKey: [dataServiceConfiguration.key, parameters] as const,
            queryFn: () => this.http.get<T>(dataServiceConfiguration.url, {
                params: new HttpParams({ fromObject: parameters })
            }),
            throwOnError: true,
            staleTime: dataServiceConfiguration.staleTime,
            retry: false
        });
    }

    protected prefetchGet<T, Y extends { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } | undefined = undefined>(dataServiceConfiguration: DataServiceConfiguration<T>, parameters?: Y) {
        return from(this.queryClient.prefetchQuery({
            queryKey: [dataServiceConfiguration.key, parameters] as const,
            queryFn: () => this.http.get<T>(dataServiceConfiguration.url, {
                params: new HttpParams({ fromObject: parameters })
            }),
            throwOnError: true,
            staleTime: dataServiceConfiguration.staleTime,
            retry: false
        }));
    }

    getWithTransform<T, Y, Z extends { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } | undefined = undefined>(dataServiceConfiguration: DataServiceConfiguration<T>, transform: OperatorFunction<T, Y>, parameters?: Z) {
        return this.query({
            queryKey: [dataServiceConfiguration.key, parameters] as const,
            queryFn: () => this.http.get<T>(dataServiceConfiguration.url, {
                params: new HttpParams({ fromObject: parameters })
            }).pipe(transform),
            throwOnError: true,
            staleTime: dataServiceConfiguration.staleTime,
            retry: false
        });
    }

    protected prefetchGetWithTransform<T, Y, Z extends { [param: string]: string | number | boolean | readonly (string | number | boolean)[]; } | undefined = undefined>(dataServiceConfiguration: DataServiceConfiguration<T>, transform: OperatorFunction<T, Y>, parameters?: Z) {
        return from(this.queryClient.prefetchQuery({
            queryKey: [dataServiceConfiguration.key, parameters] as const,
            queryFn: () => this.http.get<T>(dataServiceConfiguration.url, {
                params: new HttpParams({ fromObject: parameters })
            }).pipe(transform),
            throwOnError: true,
            staleTime: dataServiceConfiguration.staleTime,
            retry: false
        }));
    }

    post<T, Y = undefined>(dataServiceConfiguration: DataServiceConfiguration<T>, body?: Y) {
        return this.query({
            queryKey: [dataServiceConfiguration.key, body] as const,
            queryFn: () => this.http.post<T>(dataServiceConfiguration.url, body, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }),
            throwOnError: true,
            staleTime: dataServiceConfiguration.staleTime,
            retry: false
        });
    }

    prefetchPost<T, Y = undefined>(dataServiceConfiguration: DataServiceConfiguration<T>, body?: Y) {
        return from(this.queryClient.prefetchQuery({
            queryKey: [dataServiceConfiguration.key, body] as const,
            queryFn: () => this.http.post<T>(dataServiceConfiguration.url, body, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }),
            throwOnError: true,
            staleTime: dataServiceConfiguration.staleTime,
            retry: false
        }));
    }

    postWithTransform<T, Y, Z = undefined>(dataServiceConfiguration: DataServiceConfiguration<T>, transform: OperatorFunction<T, Y>, body?: Z) {
        return this.query({
            queryKey: [dataServiceConfiguration.key, body] as const,
            queryFn: () => this.http.post<T>(dataServiceConfiguration.url, body, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(transform),
            throwOnError: true,
            staleTime: dataServiceConfiguration.staleTime,
            retry: false
        });
    }

    postWithTransformPaged<T, Y, Z = undefined>(dataServiceConfiguration: DataServiceConfiguration<T>, transform: OperatorFunction<T[], Y>, body?: Z) {
        return this.query({
            queryKey: [dataServiceConfiguration.key, 'all', body] as const,
            queryFn: () => this.postAllPages(dataServiceConfiguration, body).pipe(transform),
            throwOnError: true,
            staleTime: dataServiceConfiguration.staleTime,
            retry: false
        });
    }

    private executePagedPost<T, Y>(dataServiceConfiguration: DataServiceConfiguration<T>, body: Y) {
        return this.http.post<PagedResult<T, Y>>(dataServiceConfiguration.url, body, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    private postAllPages<T, U>(dataServiceConfiguration: DataServiceConfiguration<T>, body?: U): Observable<T[]> {
        return this.executePagedPost(dataServiceConfiguration, body).pipe(
            expand(({ nextPage }) => nextPage ? this.executePagedPost(dataServiceConfiguration, nextPage) : EMPTY),
            concatMap(({ results }) => results),
            toArray(),
        );
    }

    prefetchPostWithTransform<T, Y, Z = undefined>(dataServiceConfiguration: DataServiceConfiguration<T>, transform: OperatorFunction<T, Y>, body?: Z) {
        return from(this.queryClient.prefetchQuery({
            queryKey: [dataServiceConfiguration.key, body] as const,
            queryFn: () => this.http.post<T>(dataServiceConfiguration.url, body, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(transform),
            throwOnError: true,
            staleTime: dataServiceConfiguration.staleTime,
            retry: false
        }));
    }

    refetchQuery(key: string, bodyOrParameters: any) {
        return from(this.queryClient.refetchQueries({
            predicate: x => x.queryKey == [key, bodyOrParameters] as const
        }));
    }

    refetchAnyQuery(key: string) {
        return from(this.queryClient.refetchQueries({
            predicate: x => x.queryKey[0] == key
        }));
    }
}