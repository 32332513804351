import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { Dictionary } from '../../../../../../interfaces/dictionary';
import { CycleDataService } from '../../../../services/cycle-data.service';
import { CycleTimeByLocationAndMonth } from '../../../../models/cycle-time-average-by-location-and-month';
import { ChartSeries } from '../../../../../shared-rail-performance/models/charts/fusion-charts/chart-series';
import { ChartSeriesData } from '../../../../../shared-rail-performance/models/charts/fusion-charts/chart-series-data';
import { QueryObserverResult } from '@tanstack/query-core';
import { DataSource } from '../../../../../shared-rail-performance/models/charts/fusion-charts/data-source';

@Component({
  selector: 'app-cycle-time-average-by-location-graph',
  templateUrl: './cycle-time-average-by-location-graph.component.html',
  styleUrl: './cycle-time-average-by-location-graph.component.css',
})
export class CycleTimeAverageByLocationGraphComponent implements OnInit {
  public queryResult$?: Observable<QueryObserverResult<DataSource, Error>>;
  public cycleTimeData?: any;
  public cycleTimeOptions?: any;

  constructor(private cycleDataService: CycleDataService) {

  }

  ngOnInit(): void {
    this.loadCycleTimeAverageByLocationGraph();
  }

  loadCycleTimeAverageByLocationGraph() {
    this.queryResult$ = this.cycleDataService.getCycleTimeAveragesByLocationAndMonthChart(this.populateChartWithData).result$;
  }

  populateChartWithData(cycleTimeByLocationAndMonthsRecords$: Observable<CycleTimeByLocationAndMonth[]>) {
    return cycleTimeByLocationAndMonthsRecords$.pipe(map((records) => {
      let chartData: ChartSeries[] = [];

      if (records && records.length) {
        let categories: string[] = records[0].cycleTimesByMonth.map(y => (`${y.month} ${y.day}`));

        records.forEach((cycleTimeAverageByLocationRecord) => {
          chartData.push({
            seriesname: cycleTimeAverageByLocationRecord.location,
            renderAs: "line",
            data: cycleTimeAverageByLocationRecord.cycleTimesByMonth.map<ChartSeriesData>(x => ({
              value: x.cycleTime?.toString(),
              tooltext: !!x.cycleTime ? `${cycleTimeAverageByLocationRecord.location} - ${x.cycleTime.toFixed(2)} Days` : `${cycleTimeAverageByLocationRecord.location} - N/A`
            }))
          });
        });

        return {
          "chart": {
            "divlineColor": "#999999",
            "divLineIsDashed": "1",
            "divLineDashLen": "1",
            "divLineGapLen": "1",
            "toolTipColor": "#ffffff",
            "toolTipBorderThickness": "0",
            "toolTipBgColor": "#000000",
            "toolTipBgAlpha": "80",
            "toolTipBorderRadius": "2",
            "toolTipPadding": "5",
            "theme": "fusion",
            "connectNullData": "1"
          },
          "categories": [
            {
              "category": categories.map(x => ({
                "label": x
              }))
            }
          ],
          "dataset": chartData
        };
      }

      return {};
    }));
  }

  // drawCycleTimeAverageByLocationGraph(dataset: CycleTimeAverageByLocation[]) {
  //   if (dataset == null || dataset.length == 0) return;

  //   const series: Dictionary<Array<CycleTimeAverageByLocation>> = new Dictionary<Array<CycleTimeAverageByLocation>>();
  //   let labels: string[] = new Array<string>();

  //   dataset.forEach((data) => {
  //     if (!series.containsKey(data.originState)) {
  //       series.add(data.originState, new Array<CycleTimeAverageByLocation>());
  //     }

  //     series.index(data.originState)?.push(data);

  //     let cycleEndDate = new Date(data.cycleEndDate);

  //     const month = cycleEndDate.toLocaleString('default', { month: 'long' });
  //     const day = cycleEndDate.getDate();
  //     let dateString = `${month} ${day}`

  //     if (labels.indexOf(dateString) < 0) {
  //       labels.push(`${month} ${day}`);
  //     }
  //   });

  //   let chartColors: string[] = [];
  //   let chartDataSets: any[] = new Array();

  //   let colorId: number = 20;

  //   series.keys().forEach((key: string) => {
  //     let cycleTime: number[] = new Array<number>();

  //     series.index(key)?.forEach((series_data: CycleTimeAverageByLocation) => {
  //       cycleTime.push(series_data.cycleTime);
  //     });

  //     let chart_dataset: any = {
  //       type: 'line', 
  //       tension: 0.1, 
  //       fill: false,
  //       label: key, 
  //       data: cycleTime,
  //       borderColor: FormattingToolsService.htmlcolors[colorId]
  //     }

  //     chartDataSets.push(chart_dataset);
  //     colorId += 1;
  //   });


  //   const documentStyle = getComputedStyle(document.documentElement);
  //   const textColor = documentStyle.getPropertyValue('--text-color');
  //   const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
  //   const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

  //   this.cycleTimeData = {
  //     labels: labels,
  //     datasets: chartDataSets
  //   };

  //   this.cycleTimeOptions = {
  //     aspectRatio: 1.6, 
  //     maintainAspectRatio: true,
  //     plugins: { 
  //       legend: { 
  //         labels: { 
  //           color: textColor 
  //         } 
  //       } 
  //     },
  //     scales: {
  //       x: { 
  //         ticks: { 
  //           color: textColorSecondary 
  //         }, 
  //         grid: { 
  //           color: surfaceBorder, 
  //           drawBorder: false 
  //         } 
  //       },
  //       y: { 
  //         grid: { 
  //           color: surfaceBorder, 
  //           drawBorder: false 
  //         }
  //       }
  //     }
  //   };
  // }
}
