<div class="content-width action-row d-flex flex-row" #reportActions>
    <div class="report-heading py-2 ms-1">
        {{this.dashboardReportFormService.dashboardType}}
    </div>
    <div class="py-2 report-list-container d-flex justify-content-center"
        [formGroup]="this.dashboardReportFormService.form">
        @if (dashboardReportFormService.reportQueryResult$ | async; as result) {
        <kendo-dropdownlist #reportDropDown [data]="result.data ?? []" [itemDisabled]="reportNameDisabled"
            [defaultItem]="placeholderReport" formControlName="selectedReport" fillMode="none" textField="name"
            valueField="id"></kendo-dropdownlist>
        }
    </div>
    <div class="py-2">
        <button class="report-header-action" (click)="manageReportsClick()">
            <svg width="18" height="10" viewBox="0 0 18 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1 6C1.55 6 2 5.55 2 5C2 4.45 1.55 4 1 4C0.45 4 0 4.45 0 5C0 5.55 0.45 6 1 6ZM1 10C1.55 10 2 9.55 2 9C2 8.45 1.55 8 1 8C0.45 8 0 8.45 0 9C0 9.55 0.45 10 1 10ZM1 2C1.55 2 2 1.55 2 1C2 0.45 1.55 0 1 0C0.45 0 0 0.45 0 1C0 1.55 0.45 2 1 2ZM5 6H17C17.55 6 18 5.55 18 5C18 4.45 17.55 4 17 4H5C4.45 4 4 4.45 4 5C4 5.55 4.45 6 5 6ZM5 10H17C17.55 10 18 9.55 18 9C18 8.45 17.55 8 17 8H5C4.45 8 4 8.45 4 9C4 9.55 4.45 10 5 10ZM4 1C4 1.55 4.45 2 5 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0H5C4.45 0 4 0.45 4 1ZM1 6C1.55 6 2 5.55 2 5C2 4.45 1.55 4 1 4C0.45 4 0 4.45 0 5C0 5.55 0.45 6 1 6ZM1 10C1.55 10 2 9.55 2 9C2 8.45 1.55 8 1 8C0.45 8 0 8.45 0 9C0 9.55 0.45 10 1 10ZM1 2C1.55 2 2 1.55 2 1C2 0.45 1.55 0 1 0C0.45 0 0 0.45 0 1C0 1.55 0.45 2 1 2ZM5 6H17C17.55 6 18 5.55 18 5C18 4.45 17.55 4 17 4H5C4.45 4 4 4.45 4 5C4 5.55 4.45 6 5 6ZM5 10H17C17.55 10 18 9.55 18 9C18 8.45 17.55 8 17 8H5C4.45 8 4 8.45 4 9C4 9.55 4.45 10 5 10ZM4 1C4 1.55 4.45 2 5 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0H5C4.45 0 4 0.45 4 1Z"
                    fill="currentColor" />
            </svg>
            Manage Reports
        </button>
    </div>
    <div class="py-2">
        <button class="report-header-action" (click)="saveReportClick()"
            [disabled]="!(dashboardReportFormService.isSaveEnabled$ | async) || !!dashboardReportFormService.pendingEditReport">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.59 0.59C14.21 0.21 13.7 0 13.17 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V4.83C18 4.3 17.79 3.79 17.41 3.42L14.59 0.59ZM9 16C7.34 16 6 14.66 6 13C6 11.34 7.34 10 9 10C10.66 10 12 11.34 12 13C12 14.66 10.66 16 9 16ZM10 6H4C2.9 6 2 5.1 2 4C2 2.9 2.9 2 4 2H10C11.1 2 12 2.9 12 4C12 5.1 11.1 6 10 6Z"
                    fill="currentColor" />
            </svg>
            Save Report
        </button>
    </div>
    <div class="py-2">
        <button class="report-header-action" (click)="filterButtonClick()">
            <svg width="18" height="12" viewBox="0 0 18 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8 12H10C10.55 12 11 11.55 11 11C11 10.45 10.55 10 10 10H8C7.45 10 7 10.45 7 11C7 11.55 7.45 12 8 12ZM0 1C0 1.55 0.45 2 1 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0H1C0.45 0 0 0.45 0 1ZM4 7H14C14.55 7 15 6.55 15 6C15 5.45 14.55 5 14 5H4C3.45 5 3 5.45 3 6C3 6.55 3.45 7 4 7Z"
                    fill="currentColor" />
            </svg>
            Filter
        </button>
    </div>
    <div class="py-2">
        <button class="report-header-action" (click)="exportDataClick()">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16 10V15C16 15.55 15.55 16 15 16H3C2.45 16 2 15.55 2 15V10C2 9.45 1.55 9 1 9C0.45 9 0 9.45 0 10V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V10C18 9.45 17.55 9 17 9C16.45 9 16 9.45 16 10ZM10 9.67L11.88 7.79C12.27 7.4 12.9 7.4 13.29 7.79C13.68 8.18 13.68 8.81 13.29 9.2L9.7 12.79C9.31 13.18 8.68 13.18 8.29 12.79L4.7 9.2C4.31 8.81 4.31 8.18 4.7 7.79C5.09 7.4 5.72 7.4 6.11 7.79L8 9.67V1C8 0.45 8.45 0 9 0C9.55 0 10 0.45 10 1V9.67Z"
                    fill="currentColor" />
            </svg>
            Export
        </button>
    </div>
</div>
<kendo-dialog *ngIf="manageReportWindowOpen$ | async" [minWidth]="250" [width]="550" [minHeight]="350"
    (close)="closeManageReportWindow()">
    @if (dashboardReportFormService.reportQueryResult$ | async; as result) {
    <app-control-status-indicator [queryObserverResult]="result"></app-control-status-indicator>
    }
    <div [formGroup]="dashboardReportFormService.form" class="h-100">
        <div *ngIf="!dashboardReportFormService.pendingDeleteReport" class="dialog-padding">
            <span class="modal-header">Manage Reports</span>
            <span class="muted">Edit existing reports.</span>
            <div class="divider"></div>
            <br />
            @if (dashboardReportFormService.reportQueryResult$ | async; as result) {
            <kendo-grid [data]="result.data ?? []" [scrollable]="'none'">
                <kendo-grid-column title="Actions" [width]="85" [headerClass]="'grid-header'" [class]="'grid-cell'">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <button #editPopup class="ellipsis-button"
                            (click)="toggleReportListEditMenuClick(dataItem)">&nbsp;</button>
                        <kendo-popup [anchor]="editPopup" (anchorViewportLeave)="dataItem.show = false"
                            *ngIf="dataItem.show">
                            <button class="popup-button" (click)="editReportClick(dataItem)">
                                <svg width="18" height="18" fill="#287DA1" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                    <path
                                        d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                                </svg>
                                Edit
                            </button>
                            <button class="popup-button" (click)="deleteReportClick(dataItem)">
                                <svg width="18" height="18" fill="#287DA1" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                    <path
                                        d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z" />
                                </svg>
                                Delete
                            </button>
                            <button class="popup-button" (click)="loadReportAndExportData(dataItem)">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="#287DA1" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16 10V15C16 15.55 15.55 16 15 16H3C2.45 16 2 15.55 2 15V10C2 9.45 1.55 9 1 9C0.45 9 0 9.45 0 10V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V10C18 9.45 17.55 9 17 9C16.45 9 16 9.45 16 10ZM10 9.67L11.88 7.79C12.27 7.4 12.9 7.4 13.29 7.79C13.68 8.18 13.68 8.81 13.29 9.2L9.7 12.79C9.31 13.18 8.68 13.18 8.29 12.79L4.7 9.2C4.31 8.81 4.31 8.18 4.7 7.79C5.09 7.4 5.72 7.4 6.11 7.79L8 9.67V1C8 0.45 8.45 0 9 0C9.55 0 10 0.45 10 1V9.67Z" />
                                </svg>
                                Export
                            </button>
                        </kendo-popup>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="name" title="Report Name" [headerClass]="'grid-header'"
                    [class]="'grid-cell'"></kendo-grid-column>
                <kendo-grid-messages noRecords="  "></kendo-grid-messages>
            </kendo-grid>
            }
            <br />
            <br />
            <div class="action-group mt-5">
                <button type="button" class="btn btn-action-secondary btn-above-overlay"
                    (click)="closeManageReportWindow()">Close</button>
            </div>
        </div>
        <div *ngIf="!!dashboardReportFormService.pendingDeleteReport"
            class="d-flex flex-column justify-content-center align-items-center h-100">
            <app-spinner-loader *ngIf="isDeleting$ | async" [showOverlay]="true"></app-spinner-loader>
            <img class="warning-icon mb-2" src="./../../../../../../assets/icons/warning.svg">
            <span class="warning-title mb-4">Delete Report</span>
            <span class="warning-text mb-4">Are you sure you want to permanently delete this report template?</span>
            <div class="d-flex justify-content-between w-75">
                <button class="btn btn-action-secondary" (click)="cancelDeleteClick()"
                    [disabled]="isDeleting$ | async">Cancel</button>
                <button class="btn btn-action-primary" (click)="confirmDeleteClick()"
                    [disabled]="isDeleting$ | async">Delete</button>
            </div>
        </div>
    </div>
</kendo-dialog>
<kendo-dialog *ngIf="saveReportWindowOpen$ | async" [minWidth]="250" [width]="550" [minHeight]="350"
    (close)="closeSaveReportWindow()">
    <app-spinner-loader *ngIf="isSaving$ | async" [showOverlay]="true"></app-spinner-loader>
    <div [formGroup]="dashboardReportFormService.form" class="dialog-padding">
        <span class="modal-header">Save Report</span>
        <span class="muted">Save the current report view, filters and settings.</span>
        <div class="divider"></div>
        <br />
        <kendo-formfield showHints="always" showErrors="always">
            <kendo-label text="Report Name">
                <kendo-textbox placeholder="Enter Name" formControlName="name"></kendo-textbox>
                <kendo-formerror
                    *ngIf="dashboardReportFormService.nameFormControl.touched && dashboardReportFormService.nameFormControl.hasError('required')">Name
                    is
                    required.</kendo-formerror>
            </kendo-label>
        </kendo-formfield>
        <br />
        <br />
        <div class="action-group mt-5">
            <button type="button" class="btn btn-action-primary" [disabled]="isSaving$ | async"
                (click)="saveClick()">Save</button>
            <button type="button" class="btn btn-action-secondary" [disabled]="isSaving$ | async"
                (click)="closeSaveReportWindow()">Cancel</button>
        </div>
    </div>
</kendo-dialog>
<ng-template #reportEditNotification>
    <app-report-edit-notification
        [reportNotificationRef]="dashboardReportFormService.editNotificationRef"></app-report-edit-notification>
</ng-template>
<app-dashboard-filters [showFilters$]="dashboardReportFormService.showFilters$"></app-dashboard-filters>
<div class="content-width">
    <router-outlet></router-outlet>
</div>
<kendo-dialog *ngIf="dashboardReportFormService.showExportDataLoading$ | async" [minWidth]="250" [width]="550"
    [minHeight]="250">
    <div class="d-flex align-items-center justify-content-center flex-column fs-5 h-100">
        <img class="warning-icon" src="./../../../../../../assets/icons/access-time.svg">
        <span class="warning-text my-4">Please wait while we load your data for export</span>
        <div class="spinner-border" role="status">
        </div>
    </div>
</kendo-dialog>