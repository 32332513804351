import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { LocationType } from '../../../../constants/location-type';
import { Period } from '../../../../constants/period';
import { DashboardFilters } from '../../../../models/filters/dashboard-filters';
import { Month } from '../../../../../shared-rail-performance/models/month';
import { SubSink } from 'subsink';
import { DashboardReportFormService } from '../../dashboard-report-layout/services/dashboard-report-form.service';
import { addMonths, startOfMonth } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class DashboardCycleTimeFormService implements OnDestroy {
  filterForm: FormGroup;

  private sub = new SubSink();

  constructor(private formBuilder: FormBuilder, private dashboardReportFormService: DashboardReportFormService) {
    let months = this.getLast6Months().map(x => x.getMonth() + 1);
    let years = this.getLast6Months().map(x => x.getFullYear()).filter(this.onlyUnique);

    this.filterForm = this.formBuilder.group({
      years: new FormControl<number[]>(years),
      months: new FormControl<number[]>(months)
    });
  }

  onlyUnique(value: any, index: any, array: string | any[]) {
    return array.indexOf(value) === index;
  }

  getLast6Months(): Date[] {
    let months: Date[] = [];
    let currentMonth = startOfMonth(new Date());

    for (let x = 0; x < 6; x++) {
      months.push(currentMonth);
      currentMonth = addMonths(currentMonth, -1);
    }

    return months;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  get yearsFormControl(): FormControl<number[]> {
    return this.filterForm.get('years') as FormControl<number[]>;
  }

  get years(): number[] {
    return this.yearsFormControl.value as number[];
  }

  get monthsFormControl(): FormControl<number[]> {
    return this.filterForm.get('months') as FormControl<number[]>;
  }

  get months(): number[] {
    return this.monthsFormControl.value as number[];
  }

  updateMonths(months: Month[]) {
    this.monthsFormControl.setValue(months.map(x => x.number));
  }

  updateYears(years: number[]) {
    this.yearsFormControl.setValue(years);
  }
}
