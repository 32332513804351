import { Component, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd, ChildActivationEnd, Data } from "@angular/router";
import { BehaviorSubject, filter, buffer, map, catchError, of } from "rxjs";
import { DashboardReportFormService } from "../../services/dashboard-report-form.service";
import { Report } from "../../../../../models/report/report";
import { NotificationService } from "@progress/kendo-angular-notification";
import { SubSink } from "subsink";
import { DashboardType } from "../../../../../constants/dashboard-type";

@Component({
  selector: 'app-dashboard-report-layout',
  templateUrl: './dashboard-report-layout.component.html',
  styleUrl: './dashboard-report-layout.component.scss',
})
export class DashboardReportLayoutComponent implements OnDestroy {
  isSaving$ = new BehaviorSubject<boolean>(false);
  isDeleting$ = new BehaviorSubject<boolean>(false);

  saveReportWindowOpen$ = new BehaviorSubject<boolean>(false);
  manageReportWindowOpen$ = new BehaviorSubject<boolean>(false);

  @ViewChild("reportEditNotification", { read: TemplateRef })
  public reportEditNotificationTemplate?: TemplateRef<unknown>;

  @ViewChild("exportLoadingNotification", { read: TemplateRef })
  public exportLoadingNotificationTemplate?: TemplateRef<unknown>;

  private sub = new SubSink();

  placeholderReport: Report = { name: 'Select Report', filters: '' };

  constructor(private router: Router,
    private route: ActivatedRoute,
    public dashboardReportFormService: DashboardReportFormService,
    private notificationService: NotificationService) {
    const routeEndEvent$ = this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd)
      );

    this.sub.sink = routeEndEvent$.subscribe(() => {
      this.dashboardReportFormService.reset();
      this.dashboardReportFormService.editNotificationRef?.hide();
      this.dashboardReportFormService
    });

    this.sub.sink = this.router.events
      .pipe(
        filter(e => e instanceof ChildActivationEnd && e.snapshot.component === this.route.component),
        buffer(routeEndEvent$),
        map(([ev]) => (ev as ChildActivationEnd).snapshot.firstChild?.data),
      )
      .subscribe((childRoute: Data | undefined) => {
        if (childRoute) {
          this.dashboardReportFormService.setDashboardType(childRoute['dashboardType']);
          this.dashboardReportFormService.filtersFormGroup.reset();
        }
      });

      this.dashboardReportFormService.exportData$.subscribe(() => {
        this.dashboardReportFormService.showExportDataLoading$.next(true);
      });

      this.dashboardReportFormService.exportDataComplete$.subscribe(() => {
        this.dashboardReportFormService.showExportDataLoading$.next(false);
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  filterButtonClick() {
    this.dashboardReportFormService.toggleFiltersVisible();
  }

  manageReportsClick() {
    this.manageReportWindowOpen$.next(true);
  }

  confirmDeleteClick() {
    this.isDeleting$.next(true);

    if (this.dashboardReportFormService.pendingDeleteReport?.id === this.dashboardReportFormService?.selectedReport) {
      this.dashboardReportFormService.selectedReportFormControl.reset();
    }

    this.sub.sink = this.dashboardReportFormService.deleteReport().subscribe(() => {
      this.isDeleting$.next(false);
    });
  }

  cancelDeleteClick() {
    this.dashboardReportFormService.cancelPendingReportDelete();
  }

  saveReportClick() {
    this.saveReportWindowOpen$.next(true);
  }

  saveClick() {
    this.isSaving$.next(true);
    this.dashboardReportFormService.nameFormControl.disable();

    this.sub.sink = this.dashboardReportFormService.saveNewReport().pipe(
      map((result) => {
        return true;
      }),
      catchError((err) => of(false))
    ).subscribe((success: boolean) => {
      this.isSaving$.next(false);
      this.dashboardReportFormService.nameFormControl.enable();

      if (success) {
        this.closeSaveReportWindow();
      }
    });
  }

  closeSaveReportWindow() {
    this.saveReportWindowOpen$.next(false);
  }

  closeManageReportWindow() {
    this.manageReportWindowOpen$.next(false);
  }

  toggleReportListEditMenuClick(reportToOpen: any) {
    this.dashboardReportFormService.toggleReportListEditMenu(reportToOpen);
  }

  exportDataClick() {
    this.dashboardReportFormService.exportData();
  }

  loadReportAndExportData(report: Report) {
    this.dashboardReportFormService.selectedReportFormControl.setValue(report);
    this.dashboardReportFormService.exportData();
  }

  deleteReportClick(report: Report) {
    this.dashboardReportFormService.setPendingReportDelete(report);
  }

  editReportClick(report: Report) {
    this.dashboardReportFormService.setPendingReportEdit(report);

    this.dashboardReportFormService.editNotificationRef?.hide();

    this.dashboardReportFormService.editNotificationRef = this.notificationService.show({
      content: this.reportEditNotificationTemplate!,
      cssClass: 'mb-5 p-3 rounded edit-notification',
      closable: true,
      position: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      width: 550
    });

    this.manageReportWindowOpen$.next(false);
  }

  reportNameDisabled(itemArgs: { dataItem: string; index: number }) {
    return itemArgs.index === -1;
  }
}
