import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { CustomerFilters } from '../models/filters/customer-filters';
import { environment } from '../../../../environments/environment';
import { DataServiceConfiguration } from './data/data-service-configuration';
import { DataQueryService } from './data/data.service';
import { SubSink } from 'subsink';
import { combineLatest, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerDataService extends DataQueryService {
  override prefetch(): Observable<any> {
    return combineLatest([this.prefetchGet(CustomerDataServiceConfiguration.AllCustomers)]);
  }

  getCustomers(filters?: CustomerFilters) {
    return this.get<string[]>(CustomerDataServiceConfiguration.AllCustomers);
  }
}

abstract class CustomerDataServiceConfiguration {
  static readonly AllCustomers = new DataServiceConfiguration<string[]>(
      'customers',
       environment.apiUrl + 'Customers'
  );
}

