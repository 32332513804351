import { Component, Input, OnDestroy } from '@angular/core';
import { NotificationRef } from '@progress/kendo-angular-notification';
import { DashboardReportFormService } from '../../services/dashboard-report-form.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-report-edit-notification',
  templateUrl: './report-edit-notification.component.html',
  styleUrl: './report-edit-notification.component.css',
})
export class ReportEditNotificationComponent implements OnDestroy {
  @Input() reportNotificationRef?: NotificationRef;

  reportName = this.dashboardReportFormService.pendingEditReport?.name;

  private sub = new SubSink();

  constructor(private dashboardReportFormService: DashboardReportFormService) {
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  saveEditButtonClick() {
    this.sub.sink = this.dashboardReportFormService.saveEditingReport().subscribe(() => {
      this.reportNotificationRef?.hide();
    });
  }

  cancelEditButtonClick() {
    this.dashboardReportFormService.cancelPendingReportEdit();
    this.reportNotificationRef?.hide();
  }
}
