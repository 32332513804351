<div class="d-flex flex-column h-100 position-relative">
    @if (dwellByLocationTableResult$ | async; as result) {
    <app-control-status-indicator [queryObserverResult]="result"></app-control-status-indicator>
    <kendo-grid [data]="result.data!" [height]="400" [rowClass]="rowClassCallback" 
                [pageSize]="networkDwellFormService.dwellByLocationTablePagingState.take"
                [skip]="networkDwellFormService.dwellByLocationTablePagingState.skip"
                [pageable]="true"
                (pageChange)="pageChange($event)"
                [scrollable]="'scrollable'">
        <kendo-grid-column field="stateProvince" title="State/Province" [width]="200">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" let-column="column">
                @if (!!dataItem.isNewStateProvince) {
                {{dataItem.stateProvince}}
                }
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="station" title="Station" [width]="300"></kendo-grid-column>
        <kendo-grid-column field="dwellType" title="Status" [width]="200"></kendo-grid-column>
        @if ((result?.data?.data?.length ?? 0) > 0) {
        <kendo-grid-column *ngFor="let column of result?.data!.data![0].columns"
            [field]="column.replace(' ', '')" format="p" [width]="getColumnWidth(column)" 
            class="percent-cell">
            <ng-template kendoGridHeaderTemplate>
                <div [innerHTML]="getColumnTitle(column, result?.data!.data![0].columns)"></div>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                @if (dataItem[column.replace(' ', '')] > 0) {
                    <span style="color: red">{{!!dataItem[column.replace(' ', '')] ? (dataItem[column.replace(' ', '')] | percent) : ''}}</span>
                }
                @else {
                    <span style="color: green">{{!!dataItem[column.replace(' ', '')] ? (dataItem[column.replace(' ', '')] * -1 | percent) : ''}}</span>
                }
            </ng-template>
        </kendo-grid-column>
        }
        <kendo-grid-column field="average" title="Avg" [sticky]="true" [width]="100" 
            format="p" headerClass="average-column-header" class="percent-cell average-column-cell">
            <ng-template kendoGridCellTemplate let-dataItem>
                @if (dataItem.average > 0) {
                    <span style="color: red">{{!!dataItem.average ? (dataItem.average | percent) : ''}}</span>
                }
                @else {
                    <span style="color: green">{{!!dataItem.average ? (dataItem.average * -1 | percent) : ''}}</span>
                }
            </ng-template></kendo-grid-column>
        <kendo-grid-messages noRecords="  "></kendo-grid-messages>
    </kendo-grid>
    }
</div>