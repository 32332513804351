export const LocationType = {
    StateProvince: 'State/Province',
    Station: 'Station',
    Region: 'Region',
    Country: 'Country'
};

export const LocationTypes = [
    LocationType.StateProvince,
    LocationType.Station,
];