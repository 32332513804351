import { NameValue } from "../../shared-rail-performance/models/name-value";

export const LoadedStatus = {
    Loaded: {
        name: 'Loaded',
        value: 'L'
    } as NameValue,
    Empty: {
        name: 'Empty',
        value: 'E'
    } as NameValue
};

export const LoadedStatuses = [
    LoadedStatus.Loaded,
    LoadedStatus.Empty
];