import { ElementRef, Injectable, NgZone } from '@angular/core';
import MapView from '@arcgis/core/views/MapView';
import GeoJSONLayer from '@arcgis/core/layers/GeoJSONLayer';
import Color from "@arcgis/core/Color";
import Graphic from "@arcgis/core/Graphic";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import ColorVariable from "@arcgis/core/renderers/visualVariables/ColorVariable";
import { MapService } from '../../../../../shared-rail-performance/services/map-service';
import * as stateProvinceGeoJson from './../../../../../../../assets/geo-json/states-geo.json';
import { FeatureCollection } from '../../../../../shared-rail-performance/models/maps/feature-collection';
import { NetworkDwellAverageByStateProvince } from '../../../../models/network-dwell/network-dwell-average-by-state-province';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NetworkDwellAverageByLocationMapService extends MapService {
  constructor(ngZone: NgZone) {
    super(ngZone);
  }

  load(mapViewElement: ElementRef, networkDwellAverageByStateProvince: NetworkDwellAverageByStateProvince[]) {
    return this.ngZone.runOutsideAngular(async () => {
      const defaultSym = new SimpleFillSymbol({
        color: new Color("white")
      });

      let max = Math.max(...networkDwellAverageByStateProvince.map(x => x.averageDwell ?? 0));
      let mean = _.mean(networkDwellAverageByStateProvince.map(x => x.averageDwell ?? 0));

      const renderer = new SimpleRenderer({
        symbol: defaultSym,
        label: "State",
        visualVariables: [
          new ColorVariable({
            field: "network-dwell-average",
            stops: this.getColorVariableStops(max, mean)
          })
        ]
      });

      let geoJson = this.populateMapData(stateProvinceGeoJson, networkDwellAverageByStateProvince);
      let geoJsonBlob = new Blob([JSON.stringify(geoJson)], {
        type: "application/json"
      });

      let stateProvinceLayer = new GeoJSONLayer({
        url: URL.createObjectURL(geoJsonBlob),
        renderer,
        outFields: ['*'],
        fields: [
          {
            type: 'string',
            name: 'state-province-name'
          },
          {
            type: 'string',
            name: 'state-province-code'
          },
          {
            type: 'double',
            name: 'network-dwell-average',
            defaultValue: 0
          }
        ],
      });

      return this.loadMap(mapViewElement, { 
        layers: [stateProvinceLayer] 
      }).then((view: MapView) => {
          view.ui.add("info", "top-right");
          //this.disableZooming(view);
          return view;
        })
        .then((view: MapView) => {
          this.debouncedClickHandler(view, stateProvinceLayer, this.getGraphics);
          return view;
        });
    });
  }

  getColorVariableStops(max: number, mean?: number): any[] {
    let adjustedMax = Math.max(Math.ceil(max), 1);
    let middle = mean ?? adjustedMax / 2;

    return [
      {
        value: 0,
        color: new Color([140, 192, 89]),
        size: 4
      },
      {
        value: middle,
        color: new Color([243, 207, 96]),
        size: 22
      },
      {
        value: adjustedMax,
        color: new Color([212, 104, 93]),
        size: 40
      }
    ];
  }

  populateMapData(stateProvinceGeoJson: FeatureCollection, networkDwellAverageByStateProvinces: NetworkDwellAverageByStateProvince[]) {
    for (let networkDwellAverageByStateProvince of networkDwellAverageByStateProvinces) {
      let stateProvinceGeoJsonRecord = stateProvinceGeoJson.features.find(x => x.properties['state-province-code'] === networkDwellAverageByStateProvince.stateProvinceCode);

      if (stateProvinceGeoJsonRecord) {
        stateProvinceGeoJsonRecord.properties['network-dwell-average'] = networkDwellAverageByStateProvince.averageDwell;
      }
    }

    return stateProvinceGeoJson;
  }

  getGraphics(response: any) {
    if (response.results.length) {
      const graphic = response.results[0].graphic as Graphic;

      const attributes = graphic.attributes;
      const name = attributes['state-province-name'];
      const dwellTime: number = attributes['network-dwell-average'];

      document!.getElementById("info")!.style.visibility = "visible";
      document!.getElementById("name")!.innerHTML = name;
      document!.getElementById("dwell-average")!.innerHTML = `${dwellTime.toPrecision(2)} Days`;
    } 
  }
}
