import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Period } from '../../../../constants/period';
import { BehaviorSubject, combineLatest, forkJoin, map, Observable, Subject, zip } from 'rxjs';
import { LocationType } from '../../../../constants/location-type';
import { Month } from '../../../../../shared-rail-performance/models/month';
import { DashboardFilters } from '../../../../models/filters/dashboard-filters';
import { SubSink } from 'subsink';
import { DashboardReportFormService } from '../../dashboard-report-layout/services/dashboard-report-form.service';
import { DataExportService } from '../../../../../shared-rail-performance/services/data-export/data-export.service';
import { RailcarDataService } from '../../../../services/railcar-data.service';
import { QueryObserverResult } from '@tanstack/query-core';
import { RailcarPerformance } from '../../../../models/railcar/railcar-performance';
import { RailcarPerformanceCommodity } from '../../../../models/railcar/railcar-performance-commodity';
import { RailcarFilters } from '../../../../models/filters/railcar-filters';
import { DashboardType } from '../../../../constants/dashboard-type';
import { RailcarVolumeHistoryByPeriod } from '../../../../models/railcar/railcar-volume-history-by-period';
import { sortBy } from 'sort-by-typescript';
import { ChartSeriesData } from '../../../../../shared-rail-performance/models/charts/fusion-charts/chart-series-data';
import { DataSource } from '../../../../../shared-rail-performance/models/charts/fusion-charts/data-source';
import { Result } from '@ngneat/query/lib/types';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { RailcarVolumeHistoryByWeekStateProvince } from '../dashboard-railcar-table/models/railcar-volume-history-by-week-state-province';
import { RailcarVolumeHistoryByWeekStation } from '../dashboard-railcar-table/models/railcar-volume-history-by-week-station';
import { GridColumn } from '../../../../../shared-rail-performance/models/grid-column';
import { StateProvince } from '../../../../../shared-rail-performance/models/location/state-province';
import { LocationDataService } from '../../../../../shared-rail-performance/services/location-data.service';
import { State } from '@progress/kendo-data-query';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DashboardRailcarFormService implements OnDestroy {
  filterForm: FormGroup;

  private sub = new SubSink();

  public performanceQueryResult$?: Observable<QueryObserverResult<RailcarPerformance, Error>>;
  public performanceCommodityQueryResult$?: Observable<QueryObserverResult<RailcarPerformanceCommodity, Error>>;
  public performanceEmptyQueryResult$?: Observable<QueryObserverResult<RailcarPerformance, Error>>;
  public performanceLoadedQueryResult$?: Observable<QueryObserverResult<RailcarPerformance, Error>>;
  public railcarVolumeHistoryByPeriodQuery$?: Observable<QueryObserverResult<DataSource, Error>>;
  public railcarVolumeHistoryByWeekLocationQueryResult$?: Observable<QueryObserverResult<GridDataResult, Error>>;
  public stateProvinceQueryResult$?: Observable<QueryObserverResult<StateProvince[], Error>>;

  public columnList: GridColumn[] = [];
  private cachedStationColumnList: GridColumn[] = [];
  private cachedStateProvinceColumnList: GridColumn[] = [];

  pagingState$ = new BehaviorSubject<State>({
    skip: 0,
    take: 20,
  });

  constructor(private formBuilder: FormBuilder,
              private railcarDataService: RailcarDataService,
              private dataExportService: DataExportService,
              private dashboardFormService: DashboardReportFormService,
              private locationService: LocationDataService) {
    this.stateProvinceQueryResult$ = this.locationService.getStatesProvinces().result$;

    let currentMonth = new Date().getMonth() + 1;
    let currentYear = new Date().getFullYear();

    this.filterForm = this.formBuilder.group({
      years: new FormControl<number[]>([currentYear]),
      months: new FormControl<number[]>([currentMonth, currentMonth - 1]),
      period: new FormControl<string>(Period.Daily),
      locationType: new FormControl<string>(LocationType.StateProvince)
    });
  }
  
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  querySummaryData() {
    let dashboardFilters = this.dashboardFormService.getFiltersByDashboard<RailcarFilters>(DashboardType.RailcarVolume);

    this.performanceQueryResult$ = this.railcarDataService.getRailcarPerformance(dashboardFilters).result$;
    this.performanceCommodityQueryResult$ = this.railcarDataService.getRailcarPerformanceCommodity(dashboardFilters).result$;
    this.performanceEmptyQueryResult$ = this.railcarDataService.getRailcarPerformanceEmpty(dashboardFilters).result$;
    this.performanceLoadedQueryResult$ = this.railcarDataService.getRailcarPerformanceLoaded(dashboardFilters).result$;
  }

  queryGraphData() {
    let dashboardFilters = this.dashboardFormService.getFiltersByDashboard<RailcarFilters>(DashboardType.RailcarVolume);
    let years = this.years
    let months = this.months;
    let period = this.period;

    dashboardFilters.years = years;
    dashboardFilters.months = months;
    dashboardFilters.period = period;

    let callbackTransformFunction =
      (railcarVolumeHistoryByPeriodRecords$: Observable<RailcarVolumeHistoryByPeriod[]>) => this.populateChartWithData(railcarVolumeHistoryByPeriodRecords$, this.period);

    this.railcarVolumeHistoryByPeriodQuery$ = this.railcarDataService.getRailcarVolumeHistoryByPeriodChartData(dashboardFilters, callbackTransformFunction).result$;
  }

  populateChartWithData(railcarVolumeHistoryByPeriodRecords$: Observable<RailcarVolumeHistoryByPeriod[]>, period: string) {
    return railcarVolumeHistoryByPeriodRecords$.pipe(map((railcarVolumeHistoryRecords) => {
      let railcars: ChartSeriesData[] = [];

      switch (period) {
        case Period.Daily:
          railcars = this.getChartSeriesDataDaily(railcarVolumeHistoryRecords);
          break;
        case Period.Weekly:
          railcars = this.getChartSeriesDataWeekly(railcarVolumeHistoryRecords);
          break;
        case Period.Monthly:
          railcars = this.getChartSeriesDataMonthly(railcarVolumeHistoryRecords);
          break;
        case Period.Quarterly:
          railcars = this.getChartSeriesDataQuarterly(railcarVolumeHistoryRecords);
          break;
        case Period.Annually:
          railcars = this.getChartSeriesDataAnnually(railcarVolumeHistoryRecords);
          break;
      }

      let dataSource: DataSource = {
        "chart": {
          "yAxisName": "Car Count",
          "theme": "fusion",
          "showLabels": "0",
          "labelDisplay": "none",
          "paletteColors": "black",
          "plotHoverEffect": false
        },
        "data": railcars
      };

      return dataSource;
    }));
  }

  public getChartSeriesDataDaily(railcarVolumeByPayPeriod: RailcarVolumeHistoryByPeriod[]) {
    let railcars: ChartSeriesData[] = [];

    let labelSkip = 1;
    let skipLabelStartCount = 500;
    let labelIndex = 0;
    let nextLabelToShow = 0;
    let currentLabelMidpoint = 1;
    let currentPeriod = "";

    if (railcarVolumeByPayPeriod.length < skipLabelStartCount) {
      labelSkip = 0;
    }

    railcarVolumeByPayPeriod.sort(sortBy('year', 'month', 'day')).forEach((railcarVolumeHistory) => {
      let periodText = formatDate(`${railcarVolumeHistory.year}-${railcarVolumeHistory.month}-01`, 'MMM yyyy', 'en-US');
      let dateText = formatDate(`${railcarVolumeHistory.year}-${railcarVolumeHistory.month}-01`, 'MMM dd yyyy', 'en-US');

      if (periodText != currentPeriod) {
        currentLabelMidpoint = Math.round(railcarVolumeHistory.day! / 2);
        currentPeriod = periodText;
      }

      let dataPoint: ChartSeriesData = {
        label: currentPeriod.replace(' ', '{br}'),
        value: (railcarVolumeHistory.volume).toString(),
        tooltext: `${dateText}{br}${railcarVolumeHistory.volume} Railcars`
      };

      if (railcarVolumeHistory.day == currentLabelMidpoint) {
        if (nextLabelToShow == labelIndex) {
          dataPoint.showLabel = "1";
          nextLabelToShow = labelIndex + labelSkip + 1;
        }

        labelIndex = labelIndex + 1;
      }

      railcars.push(dataPoint);
    });

    return railcars;
  }

  public getChartSeriesDataWeekly(railcarVolumeByPayPeriod: RailcarVolumeHistoryByPeriod[]) {
    let railcars: ChartSeriesData[] = [];

    let labelSkip = 13;
    let skipLabelStartCount = 26;
    let labelIndex = 0;
    let nextLabelToShow = 0;
    let currentPeriod = "";

    if (railcarVolumeByPayPeriod.length < skipLabelStartCount) {
      labelSkip = 0;
    }

    railcarVolumeByPayPeriod.sort(sortBy('year', 'week')).forEach((railcarVolumeHistory) => {
      let periodText = `${railcarVolumeHistory.year}{br}Week ${railcarVolumeHistory.week}`;
      let dateText = `${railcarVolumeHistory.year}{br}Week ${railcarVolumeHistory.week}`;

      if (periodText != currentPeriod) {
        currentPeriod = periodText;
      }

      let dataPoint: ChartSeriesData = {
        label: currentPeriod.replace(' ', '{br}'),
        value: (railcarVolumeHistory.volume).toString(),
        tooltext: `${dateText}{br}${railcarVolumeHistory.volume} Railcars`
      };

      if (nextLabelToShow == labelIndex) {
        dataPoint.showLabel = "1";
        nextLabelToShow = labelIndex + labelSkip + 1;
      }

      labelIndex = labelIndex + 1;

      railcars.push(dataPoint);
    });

    return railcars;
  }

  public getChartSeriesDataMonthly(railcarVolumeByPayPeriod: RailcarVolumeHistoryByPeriod[]) {
    let railcars: ChartSeriesData[] = [];

    let labelSkip = 3;
    let skipLabelStartCount = 24;
    let labelIndex = 0;
    let nextLabelToShow = 0;
    let currentPeriod = "";

    if (railcarVolumeByPayPeriod.length < skipLabelStartCount) {
      labelSkip = 0;
    }

    railcarVolumeByPayPeriod.sort(sortBy('year', 'month')).forEach((railcarVolumeHistory) => {
      let month = formatDate(`${railcarVolumeHistory.year}-${railcarVolumeHistory.month}-01`, 'MMM yyyy', 'en-US');
      let periodText = month;
      let dateText = month;

      if (periodText != currentPeriod) {
        currentPeriod = periodText;
      }

      let dataPoint: ChartSeriesData = {
        label: currentPeriod.replace(' ', '{br}'),
        value: (railcarVolumeHistory.volume).toString(),
        tooltext: `${dateText}{br}${railcarVolumeHistory.volume} Railcars`
      };

      if (nextLabelToShow == labelIndex) {
        dataPoint.showLabel = "1";
        nextLabelToShow = labelIndex + labelSkip + 1;
      }

      labelIndex = labelIndex + 1;

      railcars.push(dataPoint);
    });

    return railcars;
  }

  public getChartSeriesDataQuarterly(railcarVolumeByPayPeriod: RailcarVolumeHistoryByPeriod[]) {
    let railcars: ChartSeriesData[] = [];

    let labelSkip = 0;
    let skipLabelStartCount = 1;
    let labelIndex = 0;
    let nextLabelToShow = 0;
    let currentPeriod = "";

    if (railcarVolumeByPayPeriod.length < skipLabelStartCount) {
      labelSkip = 0;
    }

    railcarVolumeByPayPeriod.sort(sortBy('year', 'quarter')).forEach((railcarVolumeHistory) => {
      let periodText = `Q${railcarVolumeHistory.quarter} ${railcarVolumeHistory.year}`;
      let dateText = periodText;

      if (periodText != currentPeriod) {
        currentPeriod = periodText;
      }

      let dataPoint: ChartSeriesData = {
        label: currentPeriod.replace(' ', '{br}'),
        value: (railcarVolumeHistory.volume).toString(),
        tooltext: `${dateText}{br}${railcarVolumeHistory.volume} Railcars`
      };

      if (nextLabelToShow == labelIndex) {
        dataPoint.showLabel = "1";
        nextLabelToShow = labelIndex + labelSkip + 1;
      }

      labelIndex = labelIndex + 1;

      railcars.push(dataPoint);
    });

    return railcars;
  }

  public getChartSeriesDataAnnually(railcarVolumeByPayPeriod: RailcarVolumeHistoryByPeriod[]) {
    let railcars: ChartSeriesData[] = [];

    let labelSkip = 0;
    let skipLabelStartCount = 1;
    let labelIndex = 0;
    let nextLabelToShow = 0;
    let currentPeriod = "";

    if (railcarVolumeByPayPeriod.length < skipLabelStartCount) {
      labelSkip = 0;
    }

    railcarVolumeByPayPeriod.sort(sortBy('year')).forEach((railcarVolumeHistory) => {
      let periodText = `${railcarVolumeHistory.year}`;
      let dateText = periodText;

      if (periodText != currentPeriod) {
        currentPeriod = periodText;
      }

      let dataPoint: ChartSeriesData = {
        label: currentPeriod.replace(' ', '{br}'),
        value: (railcarVolumeHistory.volume).toString(),
        tooltext: `${dateText}{br}${railcarVolumeHistory.volume} Railcars`
      };

      if (nextLabelToShow == labelIndex) {
        dataPoint.showLabel = "1";
        nextLabelToShow = labelIndex + labelSkip + 1;
      }

      labelIndex = labelIndex + 1;

      railcars.push(dataPoint);
    });

    return railcars;
  }

  queryTableData() {
    if (this.locationType == LocationType.StateProvince) {
      this.loadStateProvinceData();
    }
    else {
      this.loadStationData();
    }
  }

  loadStateProvinceColumns() {
    this.sub.sink = this.stateProvinceQueryResult$?.subscribe((result) => {
      this.columnList = [];

      result.data?.forEach((stateProvince) => {
        this.columnList.push({
          field: stateProvince.code.toLowerCase(),
          title: stateProvince.code
        });
      });

      this.cachedStateProvinceColumnList = [...this.columnList];
    });
  }

  loadStationData() {
    let pagingState = this.pagingState$.value;
    let filters = this.dashboardFormService.getFiltersByDashboard<RailcarFilters>(DashboardType.RailcarVolume);
    filters.locationType = this.locationType;
    filters.months = this.months;
    filters.years = this.years;

    RailcarFilters.setPaging(filters, pagingState.take!, pagingState.skip!);

    if (this.cachedStationColumnList) {
      this.columnList = [...this.cachedStationColumnList];
    }

    let callbackTransformFunction =
      (railcarVolumeHistoryByWeekLocation$: Observable<RailcarVolumeHistoryByWeekStation[]>) => this.populateStationTableWithData(railcarVolumeHistoryByWeekLocation$);

    this.railcarVolumeHistoryByWeekLocationQueryResult$ = this.railcarDataService.getRailcarVolumeHistoryByWeekLocationTableData(filters, callbackTransformFunction).result$;
  }

  loadStateProvinceData() {
    let pagingState = this.pagingState$.value;
    let filters = this.dashboardFormService.getFiltersByDashboard<RailcarFilters>(DashboardType.RailcarVolume);
    filters.locationType = this.locationType;
    filters.months = this.months;
    filters.years = this.years;

    RailcarFilters.setPaging(filters, pagingState.take!, pagingState.skip!);

    let callbackTransformFunction =
      (railcarVolumeHistoryByWeekStateProvince$: Observable<RailcarVolumeHistoryByWeekStateProvince[]>) => this.populateStateProvinceTableWithData(railcarVolumeHistoryByWeekStateProvince$);

    this.railcarVolumeHistoryByWeekLocationQueryResult$ = this.railcarDataService.getRailcarVolumeHistoryByWeekLocationTableData(filters, callbackTransformFunction).result$;
  }

  populateStationTableWithData(railcarVolumeHistoryByWeekStation$: Observable<RailcarVolumeHistoryByWeekStation[]>) {
    let gridDataResult: GridDataResult = {
      data: [],
      total: 0
    };

    return railcarVolumeHistoryByWeekStation$.pipe(map((railcarVolumeHistoryByWeekStationRecords) => {
      let firstRecord = railcarVolumeHistoryByWeekStationRecords[0];

      this.columnList = [
        {
          title: firstRecord.station1Name,
          field: 'station1Value'
        },
        {
          title: firstRecord.station2Name,
          field: 'station2Value'
        },
        {
          title: firstRecord.station3Name,
          field: 'station3Value'
        },
        {
          title: firstRecord.station4Name,
          field: 'station14Value'
        },
        {
          title: firstRecord.station5Name,
          field: 'station5Value'
        },
        {
          title: firstRecord.station6Name,
          field: 'station6Value'
        },
        {
          title: firstRecord.station7Name,
          field: 'station7Value'
        },
        {
          title: firstRecord.station8Name,
          field: 'station8Value'
        },
        {
          title: firstRecord.station9Name,
          field: 'station9Value'
        },
        {
          title: firstRecord.station10Name,
          field: 'station10Value'
        }
      ];

      this.cachedStationColumnList = [...this.columnList];

      let currentYear = 0;

      railcarVolumeHistoryByWeekStationRecords.forEach((record) => {
        if (record.year != currentYear) {
          currentYear = record.year!;
        }
        else {
          record.year = undefined;
        }
      });

      gridDataResult.data = railcarVolumeHistoryByWeekStationRecords;
      gridDataResult.total = railcarVolumeHistoryByWeekStationRecords.length;

      return gridDataResult;
    }));
  }

  populateStateProvinceTableWithData(railcarVolumeHistoryByWeekStateProvinceRecords$: Observable<RailcarVolumeHistoryByWeekStateProvince[]>) {
    let gridDataResult: GridDataResult = {
      data: [],
      total: 0
    };

    return railcarVolumeHistoryByWeekStateProvinceRecords$.pipe(map((railcarVolumeHistoryByWeekStateProvinceRecords) => {
      let currentYear = 0;
      let columnsToShow: GridColumn[] = [];

      //Only add columns from cached list to column list if records are present
      for (let column of this.cachedStateProvinceColumnList) {
        let record = railcarVolumeHistoryByWeekStateProvinceRecords.find(x => !!(x as any)[column.field]);

        if (record && !!(record as any)[column.field] && (record as any)[column.field] > 0) {
          columnsToShow.push(column);
        }
      }

      this.columnList = [...columnsToShow];

      railcarVolumeHistoryByWeekStateProvinceRecords.forEach((record) => {
        if (record.year != currentYear) {
          currentYear = record.year!;
        }
        else {
          record.year = undefined;
        }
      });

      gridDataResult.data = railcarVolumeHistoryByWeekStateProvinceRecords;
      gridDataResult.total = railcarVolumeHistoryByWeekStateProvinceRecords.length;

      return gridDataResult;
    }));
  }

  get yearsFormControl(): FormControl<number[]> {
    return this.filterForm.get('years') as FormControl<number[]>;
  }

  get years(): number[] {
    return this.yearsFormControl.value as number[];
  }

  get monthsFormControl(): FormControl<number[]> {
    return this.filterForm.get('months') as FormControl<number[]>;
  }

  get months(): number[] {
    return this.monthsFormControl.value as number[];
  }

  get periodFormControl(): FormControl<string> {
    return this.filterForm.get('period') as FormControl<string>;
  }

  get period(): string {
    return this.periodFormControl.value as string;
  }

  get locationTypeControl(): FormControl<string> {
    return this.filterForm.get('locationType') as FormControl<string>;
  }

  get locationType(): string {
    return this.locationTypeControl.value as string;
  }

  updateMonths(months: Month[]) {
    this.monthsFormControl.setValue(months.map(x => x.number));
  }

  updateYears(years: number[]) {
    this.yearsFormControl.setValue(years);
  }

  updatePeriod(period: string) {
    this.periodFormControl.setValue(period);
  }

  updateLocationType(locationType: string) {
    this.locationTypeControl.setValue(locationType);
  }

  exportData() {
    this.sub.sink = combineLatest({
      performance: this.performanceQueryResult$!,
      commodity: this.performanceCommodityQueryResult$!,
      empty: this.performanceEmptyQueryResult$!,
      loaded: this.performanceLoadedQueryResult$!,
      historyByPeriod: this.railcarVolumeHistoryByPeriodQuery$!,
      historyByPeriodLocation: this.railcarVolumeHistoryByWeekLocationQueryResult$!
    }).subscribe(({performance, commodity, empty, loaded, historyByPeriod, historyByPeriodLocation}) => {
      let workbook = this.dataExportService.createWorkbook();

      this.dataExportService.addWorksheet(workbook, 'Performance', [performance.data], [
        { header: 'Railcar Total', key: 'railcarTotal' },
        { header: 'Railcar In-Motion', key: 'railcarMotion' },
        { header: 'Railcar Origin', key: 'railcarOrigin' },
        { header: 'Railcar Destination', key: 'railcarDestination' },
        { header: 'No Waybill', key: 'no_waybill' },
      ]);

      this.dataExportService.addWorksheet(workbook, 'Commodity', [commodity.data], [
        { header: 'Commodity 1', key: 'commodity1Type' },
        { header: 'Commodity 1 Cars', key: 'commodity1Cars' },
        { header: 'Commodity 2', key: 'commodity2Type' },
        { header: 'Commodity 2 Cars', key: 'commodity2Cars' },
        { header: 'Commodity 3', key: 'commodity3Type' },
        { header: 'Commodity 3 Cars', key: 'commodity3Cars' },
        { header: 'Commodity 4', key: 'commodity4Type' },
        { header: 'Commodity 4 Cars', key: 'commodity4Cars' }
      ]);

      this.dataExportService.addWorksheet(workbook, 'Empty', [empty.data], [
        { header: 'Railcar Total', key: 'railcarTotal' },
        { header: 'Railcar In-Motion', key: 'railcarMotion' },
        { header: 'Railcar Origin', key: 'railcarOrigin' },
        { header: 'Railcar Destination', key: 'railcarDestination' },
        { header: 'No Waybill', key: 'no_waybill' },
      ]);

      this.dataExportService.addWorksheet(workbook, 'Loaded', [loaded.data], [
        { header: 'Railcar Total', key: 'railcarTotal' },
        { header: 'Railcar In-Motion', key: 'railcarMotion' },
        { header: 'Railcar Origin', key: 'railcarOrigin' },
        { header: 'Railcar Destination', key: 'railcarDestination' },
        { header: 'No Waybill', key: 'no_waybill' },
      ]);

      this.dataExportService.addWorksheet(workbook, 'History By Period', historyByPeriod.data?.data?.map(x => ({ date: x?.tooltext?.split('{br}')[0], value: x?.tooltext?.split('{br}')[1] })) ?? [], [
        { header: 'Date', key: 'date' },
        { header: 'Value', key: 'value' },
      ]);

      this.dataExportService.addWorksheet(workbook, 'History By Period & Location', historyByPeriodLocation.data?.data ?? [], [
        { header: 'Week Start Date', key: 'weekStartDate' },
        { header: 'Total', key: 'total' },
        ...this.cachedStateProvinceColumnList.map(x => ({
          header: x.field.toUpperCase(),
          key: x.field
        }))
      ]);
      
      this.dataExportService.saveWorkbook(workbook, 'RailcarVolume');

      this.dashboardFormService.exportDataComplete$.next();
    });
  }
}
