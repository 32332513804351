<div class="content-width" [class.content-width-padded]="showFilters$ | async" [formGroup]="dashboardFormService.filtersFormGroup">
    <kendo-expansionpanel #filterExpansionPanel class="hide-expander-header">
        <div class="dashboard" style="background-color: #F4F8FA;">
            <div class="row">
                <div class="col-3" formGroupName="common">
                    @if (customersQueryResults$ | async; as result) {
                    <kendo-label text="Customer">
                        <kendo-multiselect [data]="result.data ?? []" [disabled]="result.isLoading"
                            [loading]="result.isLoading" [placeholder]="result.isLoading ? 'Loading' : ''"
                            [virtual]="virtual" formControlName="customers">
                        </kendo-multiselect>
                    </kendo-label>
                    }
                </div>
                <div class="col-3" formGroupName="common">
                    @if (countriesQueryResults$ | async; as result) {
                    <kendo-label text="Country">
                        <kendo-multiselect [data]="result.data ?? []" [disabled]="result.isLoading"
                            [loading]="result.isLoading" [placeholder]="result.isLoading ? 'Loading' : ''"
                            [virtual]="virtual" formControlName="countries" textField="name" valueField="code"
                            [valuePrimitive]="true">
                        </kendo-multiselect>
                    </kendo-label>
                    }
                </div>
                <div class="col-3" formGroupName="common">
                    @if (regionsQueryResults$ | async; as result) {
                    <kendo-label text="Region">
                        <kendo-multiselect [data]="result.data ?? []" [disabled]="result.isLoading"
                            [loading]="result.isLoading" [placeholder]="result.isLoading ? 'Loading' : ''"
                            [virtual]="virtual" formControlName="regions" textField="region" valueField="region"
                            [valuePrimitive]="true">
                        </kendo-multiselect>
                    </kendo-label>
                    }
                </div>
                <div class="col-3" formGroupName="common">
                    @if (statesProvincesQueryResults$ | async; as result) {
                    <kendo-label text="State/Province">
                        <kendo-multiselect [data]="result.data ?? []" [disabled]="result.isLoading"
                            [loading]="result.isLoading" [placeholder]="result.isLoading ? 'Loading' : ''"
                            [virtual]="virtual" formControlName="statesProvinces" textField="name" valueField="code"
                            [valuePrimitive]="true">
                        </kendo-multiselect>
                    </kendo-label>
                    }
                </div>
                <div class="col-3" formGroupName="common">
                    @if (stationsQueryResults$ | async; as result) {
                    <kendo-label text="Station">
                        <kendo-multiselect [data]="result.data ?? []" [disabled]="result.isLoading"
                            [loading]="result.isLoading" [placeholder]="result.isLoading ? 'Loading' : ''"
                            [virtual]="virtual" formControlName="stations" textField="city" valueField="splc"
                            [valuePrimitive]="true">
                        </kendo-multiselect>
                    </kendo-label>
                    }
                </div>
                <div class="col-3" formGroupName="common">
                    @if (commoditiesQueryResults$ | async; as result) {
                    <kendo-label text="Commodity">
                        <kendo-multiselect [data]="result.data ?? []" [disabled]="result.isLoading"
                            [loading]="result.isLoading" [placeholder]="result.isLoading ? 'Loading' : ''"
                            [virtual]="virtual" formControlName="commodities">
                        </kendo-multiselect>
                    </kendo-label>
                    }
                </div>
                <div class="col-3" formGroupName="railcar" *ngIf="dashboardFormService.dashboardType === DashboardType.RailcarVolume">
                    <kendo-label text="In Motion">
                        <kendo-multiselect [data]="inMotionValues" formControlName="motion"></kendo-multiselect>
                    </kendo-label>
                </div>
                <div class="col-3">
                    <kendo-label formGroupName="common" text="Loaded/Empty">
                        <kendo-multiselect [data]="loadedValues" formControlName="loaded" textField="name"
                            valueField="value" [valuePrimitive]="true"></kendo-multiselect>
                    </kendo-label>
                </div>
                <div class="col-3" formGroupName="networkDwell" *ngIf="dashboardFormService.dashboardType === DashboardType.NetworkDwell">
                    <kendo-label text="Travel Status">
                        <kendo-multiselect [data]="travelStatusValues"
                            formControlName="travelStatus"></kendo-multiselect>
                    </kendo-label>
                </div>
                <div class="col-3" formGroupName="networkDwell" *ngIf="dashboardFormService.dashboardType === DashboardType.NetworkDwell">
                    <kendo-label text="Minimum Railcar Count">
                        <kendo-numerictextbox formControlName="minimumRailcarCount" [step]="1" [spinners]="false"></kendo-numerictextbox>
                    </kendo-label>
                </div>
                <div class="col-3" formGroupName="networkDwell" *ngIf="dashboardFormService.dashboardType === DashboardType.NetworkDwell">
                    <kendo-label text="Minimum Dwell (days)">
                        <kendo-numerictextbox formControlName="minimumDwellDays" [step]="1" [spinners]="false"></kendo-numerictextbox>
                    </kendo-label>
                </div>
            </div>
            <br />
            <div class="action-group">
                <button class="btn btn-action-primary" (click)="applyFilters()"
                    [disabled]="this.dashboardFormService.filtersFormGroup.pristine">Apply</button>
                <button class="btn btn-action-secondary" (click)="clearFilters()">Clear</button>
            </div>
        </div>
    </kendo-expansionpanel>
</div>

<!-- <kendo-popup [anchor]="anchor!" *ngIf="showFilters$ | async">
    
</kendo-popup> -->