import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Component({
  selector: 'app-performance-metric',
  templateUrl: './performance-metric.component.html',
  styleUrl: './performance-metric.component.css',
})
export class PerformanceMetricComponent {
  @Input() label?: string;
  @Input() value?: string | number;
  @Input() decimalsToDisplay?: number;
  @Input() unitOfMeasure?: string;
  @Input() isLoading?: boolean;
  @Input() isError?: boolean;
  @Input() showIfZero?: boolean = true;

  get valueToDisplay() {
    if (typeof this.value === 'number') {
      return this.value.toFixed(this.decimalsToDisplay ?? 0);
    }

    return this.value;
  }
}
