import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { RailcarDataService } from '../../../../services/railcar-data.service';
import { QueryObserverResult } from '@tanstack/query-core';
import { BehaviorSubject, combineLatest, first, map, Observable, startWith } from 'rxjs';
import { RailcarVolumeHistoryByWeekStation } from './models/railcar-volume-history-by-week-station';
import { StateProvince } from '../../../../../shared-rail-performance/models/location/state-province';
import { LocationDataService } from '../../../../../shared-rail-performance/services/location-data.service';
import { addDays } from 'date-fns';
import { State } from '@progress/kendo-data-query';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { RailcarFilters } from '../../../../models/filters/railcar-filters';
import { LocationType, LocationTypes } from '../../../../constants/location-type';
import { DashboardRailcarFormService } from '../services/dashboard-railcar-form.service';
import { GridColumn } from '../../../../../shared-rail-performance/models/grid-column';
import { RailcarVolumeHistoryByWeekStateProvince } from './models/railcar-volume-history-by-week-state-province';
import { PagedResult } from '../../../../../shared-rail-performance/models/paging/paged-result';
import { SubSink } from 'subsink';
import { DashboardReportFormService } from '../../dashboard-report-layout/services/dashboard-report-form.service';
import { DashboardType } from '../../../../constants/dashboard-type';

@Component({
  selector: 'app-dashboard-railcar-table',
  templateUrl: './dashboard-railcar-table.component.html',
  styleUrl: './dashboard-railcar-table.component.css',
})
export class DashboardRailcarTableComponent implements OnInit, OnDestroy {
  public addDays = addDays;

  public locationTypes = LocationTypes;

  private sub = new SubSink();

  constructor(public formService: DashboardRailcarFormService,
    private dashboardFormService: DashboardReportFormService,
    private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.formService.loadStateProvinceColumns();
    this.formService.queryTableData();
    
    this.sub.sink = combineLatest([
      this.formService.yearsFormControl.valueChanges.pipe(startWith([])), 
      this.formService.monthsFormControl.valueChanges.pipe(startWith([])),
      this.formService.locationTypeControl.valueChanges.pipe(startWith(undefined)),
      this.dashboardFormService.filtersApplied$.pipe(startWith(undefined as void)),
      this.formService.pagingState$.pipe(startWith({}))]).subscribe(() => {
        this.formService.queryTableData();
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  get railcarVolumeHistoryByWeekLocationQueryResult$() {
    return this.formService.railcarVolumeHistoryByWeekLocationQueryResult$;
  }

  public pageChange(state: PageChangeEvent): void {
    this.formService.pagingState$.next(state);
  }

  locationTypeChange(event: any) {
    let locationTypeIndex = event[0];
    let locationType = LocationTypes[locationTypeIndex];

    this.formService.locationTypeControl.setValue(locationType);
    this.changeDetectorRef.detectChanges();
  }

  getColumnWidth(column: GridColumn) {
    return Math.max(70, (column.title?.length ?? 5) * 15);
  }
}
