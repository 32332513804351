import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Months } from '../../data/months';
import { YearsMonths } from './models/years-months';
import { Month } from '../../models/month';
import { FormGroup } from '@angular/forms';
import { sortBy } from 'sort-by-typescript';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrl: './date-filter.component.css',
})
export class DateFilterComponent {
  @Input() formGroup!: FormGroup;
  @Input() yearsFormControlName!: string;
  @Input() monthsFormControlName!: string;

  @Input() startYear: number = 2021;

  years: number[] = [];
  months: Month[] = Months;

  constructor() {
    this.buildYearList();
  }

  buildYearList() {
    this.years = [];

    let currentYear = new Date().getFullYear();
    let yearToAdd = this.startYear;

    while (yearToAdd <= currentYear) {
      this.years.push(yearToAdd);
      yearToAdd += 1;
    }

    this.years = this.years.sort(sortBy('-'));
  }

}

