import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { LocationType } from '../../../../constants/location-type';
import { Period } from '../../../../constants/period';
import { DashboardFilters } from '../../../../models/filters/dashboard-filters';
import { Month } from '../../../../../shared-rail-performance/models/month';
import { SubSink } from 'subsink';
import { DashboardReportFormService } from '../../dashboard-report-layout/services/dashboard-report-form.service';
import { addMonths, startOfMonth } from 'date-fns';
import { NetworkDwellFilters } from '../../../../models/filters/network-dwell-filters';
import { DashboardType } from '../../../../constants/dashboard-type';
import { NetworkDwellDataService } from '../../../../services/network-dwell-data.service';
import { QueryObserverResult } from '@tanstack/query-core';
import { BehaviorSubject, combineLatest, filter, from, map, mergeMap, Observable } from 'rxjs';
import { NetworkDwellCommodity } from '../../../../models/network-dwell/network-dwell-commodity';
import { NetworkDwellLocation } from '../../../../models/network-dwell/network-dwell-location';
import { NetworkDwellPerformance } from '../../../../models/network-dwell/network-dwell-performance';
import { NetworkDwellStation } from '../../../../models/network-dwell/network-dwell-station';
import { DataExportService } from '../../../../../shared-rail-performance/services/data-export/data-export.service';
import { NetworkDwellAverageByStateProvince } from '../../../../models/network-dwell/network-dwell-average-by-state-province';
import { NetworkDwellAverageByStation } from '../../../../models/network-dwell/network-dwell-average-by-station';
import { DashboardDwellMapService } from '../dashboard-dwell-by-location-map/dashboard-dwell-map.service';
import MapView from '@arcgis/core/views/MapView';
import { DataSource } from '../../../../../shared-rail-performance/models/charts/fusion-charts/data-source';
import { Categories } from '../../../../../shared-rail-performance/models/charts/fusion-charts/categories';
import { groupBy } from '../../../../../shared-rail-performance/functions/array-functions';
import { NetworkDwellAverageByMonthStateProvince as NetworkDwellAverageByMonthLocation } from '../../../../models/network-dwell/network-dwell-average-by-month-state-province';
import { Collection } from 'lodash';
import { sortBy } from 'sort-by-typescript';
import { ChartSeries } from '../../../../../shared-rail-performance/models/charts/fusion-charts/chart-series';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { NetworkDwellStatusRecord } from '../../../../models/network-dwell/status/network-dwell-status-record';
import { NetworkDwellStatusGridRow } from '../../../../models/network-dwell/status/network-dwell-status-grid-row';
import { GridColumn } from '../../../../../shared-rail-performance/models/grid-column';
import { PagedResult } from '../../../../../shared-rail-performance/models/paging/paged-result';
import { State } from '@progress/kendo-data-query';
import _ from 'lodash';
import { NetworkDwellCount } from '../../../../models/network-dwell/network-dwell-count';
import { ChartSeriesData } from '../../../../../shared-rail-performance/models/charts/fusion-charts/chart-series-data';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DashboardNetworkDwellFormService implements OnDestroy {
  dwellSummaryQueryResult$?: Observable<QueryObserverResult<NetworkDwellPerformance, Error>>;
  dwellLocationQueryResult$?: Observable<QueryObserverResult<NetworkDwellLocation, Error>>;
  dwellCommodityQueryResult$?: Observable<QueryObserverResult<NetworkDwellCommodity, Error>>;
  dwellStationQueryResult$?: Observable<QueryObserverResult<NetworkDwellStation, Error>>;
  stateProvinceQueryResult$?: Observable<QueryObserverResult<NetworkDwellAverageByStateProvince[], Error>>;
  stationQueryResult$?: Observable<QueryObserverResult<NetworkDwellAverageByStation[], Error>>;
  dwellByLocationQueryResult$?: Observable<QueryObserverResult<DataSource, Error>>;
  dwellByLocationTableResult$?: Observable<QueryObserverResult<GridDataResult, Error>>;
  dwellTotalChartResult$?: Observable<QueryObserverResult<DataSource, Error>>;

  filterForm: FormGroup;

  dwellByLocationTablePagingState: State = {
    skip: 0,
    take: 100,
  };

  private total?: number;

  private mapView?: MapView;

  public isMapLoading$ = new BehaviorSubject<boolean>(true);

  private sub = new SubSink();

  constructor(private formBuilder: FormBuilder,
              private dashboardReportFormService: DashboardReportFormService,
              private networkDwellDataService: NetworkDwellDataService,
              private dataExportService: DataExportService,
              private dashboardDwellMapService: DashboardDwellMapService,
            ) {
    let months = this.getLast6Months().map(x => x.getMonth() + 1);
    let years = this.getLast6Months().map(x => x.getFullYear()).filter(this.onlyUnique);

    this.filterForm = this.formBuilder.group({
      years: new FormControl<number[]>(years),
      months: new FormControl<number[]>(months),
      mapLocationType: new FormControl<string>(LocationType.StateProvince),
      graphLocationType: new FormControl<string>(LocationType.StateProvince),
      period: new FormControl<string>(Period.Daily)
    });
  }

  onlyUnique(value: any, index: any, array: string | any[]) {
    return array.indexOf(value) === index;
  }

  getLast6Months(): Date[] {
    let months: Date[] = [];
    let currentMonth = startOfMonth(new Date());

    for (let x = 0; x < 6; x++) {
      months.push(currentMonth);
      currentMonth = addMonths(currentMonth, -1);
    }

    return months;
  }

  ngOnDestroy(): void {
    if (this.mapView) {
      this.mapView.destroy();
    }

    this.sub.unsubscribe();
  }

  get yearsFormControl(): FormControl<number[]> {
    return this.filterForm.get('years') as FormControl<number[]>;
  }

  get years(): number[] {
    return this.yearsFormControl.value as number[];
  }

  get monthsFormControl(): FormControl<number[]> {
    return this.filterForm.get('months') as FormControl<number[]>;
  }

  get months(): number[] {
    return this.monthsFormControl.value as number[];
  }

  get mapLocationTypeControl(): FormControl<string> {
    return this.filterForm.get('mapLocationType') as FormControl<string>;
  }

  get mapLocationType(): string {
    return this.mapLocationTypeControl.value as string;
  }

  get graphLocationTypeControl(): FormControl<string> {
    return this.filterForm.get('graphLocationType') as FormControl<string>;
  }

  get graphLocationType(): string {
    return this.graphLocationTypeControl.value as string;
  }

  get periodControl(): FormControl<string> {
    return this.filterForm.get('period') as FormControl<string>;
  }

  get period(): string {
    return this.periodControl.value as string;
  }

  querySummaryData() {
    let filters = this.dashboardReportFormService.getFiltersByDashboard<NetworkDwellFilters>(DashboardType.NetworkDwell);

    this.dwellSummaryQueryResult$ = this.networkDwellDataService.getNetworkDwellSummary(filters).result$;
    this.dwellLocationQueryResult$ = this.networkDwellDataService.getNetworkDwellByCountry(filters).result$;
    this.dwellCommodityQueryResult$ = this.networkDwellDataService.getNetworkDwellCommodity(filters).result$;
    this.dwellStationQueryResult$ = this.networkDwellDataService.getNetworkDwellStation(filters).result$;
  }

  queryLocationMapData(mapViewEl: ElementRef, expandButtonClick: () => void) {
    if (this.mapLocationType === LocationType.StateProvince) {
      this.loadStateProvinceMapWithData(mapViewEl, expandButtonClick);
    }
    else {
      this.loadStationMapWithData(mapViewEl, expandButtonClick);
    }
  }

  queryStateProvinceData() {
    let filters = this.dashboardReportFormService.getFiltersByDashboard<NetworkDwellFilters>(DashboardType.NetworkDwell);
    filters.months = this.months;
    filters.years = this.years;

    this.stateProvinceQueryResult$ = this.networkDwellDataService.getNetworkDwellAveragesByStateProvince(filters).result$;
  }

  queryStationData() {
    let filters = this.dashboardReportFormService.getFiltersByDashboard<NetworkDwellFilters>(DashboardType.NetworkDwell);
    filters.months = this.months;
    filters.years = this.years;

    this.stationQueryResult$ = this.networkDwellDataService.getNetworkDwellAveragesByStation(filters).result$;
  }

  loadStationMapWithData(mapViewEl: ElementRef, expandButtonClick: () => void) {
    this.isMapLoading$.next(true);
    this.queryStationData();

    this.sub.sink = this.stationQueryResult$?.pipe(mergeMap((results) => {
      return from(this.dashboardDwellMapService.loadStation(mapViewEl, results.data ?? [], expandButtonClick).then((mapView) => {
        mapView = mapView;
      })).pipe(map(() => {
        return results;
      }))
    }), filter((results) => {
      return !results.isLoading;
    })).subscribe(() => {
      this.isMapLoading$.next(false);
    });
  }

  loadStateProvinceMapWithData(mapViewEl: ElementRef, expandButtonClick: () => void) {
    this.isMapLoading$.next(true);
    this.queryStateProvinceData();

    this.sub.sink = this.stateProvinceQueryResult$?.pipe(mergeMap((results) => {
      return from(this.dashboardDwellMapService.loadStateProvince(mapViewEl, results.data ?? [], expandButtonClick).then((mapView) => {
        this.mapView = mapView;
      })).pipe(map(() => {
        return results;
      }))
    }), filter((results) => {
      return !results.isLoading;
    })).subscribe(() => {
      this.isMapLoading$.next(false);
    });
  }

  queryDwellByLocationTypeData() {
    let filters = this.dashboardReportFormService.getFiltersByDashboard<NetworkDwellFilters>(DashboardType.NetworkDwell);
    filters.months = this.months;
    filters.years = this.years;
    filters.locationType = this.graphLocationType;

    let callbackTransformFunction =
      (networkDwellAverageByMonthLocationRecords$: Observable<NetworkDwellAverageByMonthLocation[]>) => this.populateChartWithData(networkDwellAverageByMonthLocationRecords$, this.graphLocationType);

    this.dwellByLocationQueryResult$ = this.networkDwellDataService.getNetworkDwellAveragesByMonthLocationChart(filters, callbackTransformFunction).result$;
  }

  populateChartWithData(networkDwellAverageByMonthLocationRecords$: Observable<NetworkDwellAverageByMonthLocation[]>, locationType: string) {
    return networkDwellAverageByMonthLocationRecords$.pipe(map((records) => {
        let categories: string[] = [];
        let chartSeries: ChartSeries[] = [];

        let groupedRecords: Collection<{
          key: string;
          items: NetworkDwellAverageByMonthLocation[];
        }>;

        if (locationType == LocationType.StateProvince) {
          groupedRecords = groupBy<NetworkDwellAverageByMonthLocation>(records, (record) => record.stateProvinceName);
        }
        else if (locationType == LocationType.Region) {
          groupedRecords = groupBy<NetworkDwellAverageByMonthLocation>(records, (record) => record.region);
        }
        else if (locationType == LocationType.Country) {
          groupedRecords = groupBy<NetworkDwellAverageByMonthLocation>(records, (record) => record.country);
        }

        groupedRecords!.sort(sortBy('key')).forEach((group) => {
          let locationSeries = {
            "seriesname": group.key,
            "renderAs": "column",
            "data": group.items.sort(sortBy('year', 'month')).map((item) => {
              return { value: (item.averageDwell ?? 0).toString() };
            }),
          };

          chartSeries.push(locationSeries);
        });

        let uniqueMonths = groupBy<NetworkDwellAverageByMonthLocation>(records.sort(sortBy('year', 'month')), (record) => formatDate(`${record.year}-${record.month}-01`, 'MMM yyyy', 'en-us'));

        uniqueMonths.forEach((uniqueMonth) => {
          categories.push(uniqueMonth.key);
        });

        let dataSource: DataSource = {
          "chart": {
            "yAxisName": "Average Dwell (Days)",
            "divlineColor": "#999999",
            "divLineIsDashed": "1",
            "divLineDashLen": "1",
            "divLineGapLen": "1",
            "toolTipColor": "#ffffff",
            "toolTipBorderThickness": "0",
            "toolTipBgColor": "#000000",
            "toolTipBgAlpha": "80",
            "toolTipBorderRadius": "2",
            "toolTipPadding": "5",
            "theme": "fusion"
          },
          categories: [Categories.getCategoriesFromStringList(categories)],
          dataset: chartSeries,
          rawData: records
        };

        return dataSource;
    }));
  }

  queryNetworkDwellByLocation() {
    let page = this.dwellByLocationTablePagingState.skip! / this.dwellByLocationTablePagingState.take!;

    this.dwellByLocationTableResult$ = this.queryNetworkDwellByLocationByPage(page);

    //Cache next page
    this.sub.sink = this.dwellByLocationTableResult$.pipe(filter((query) => query.isSuccess)).subscribe((query) => {
      this.total = query.data?.total;
      this.sub.sink = this.queryNetworkDwellByLocationByPage(page + 1).subscribe();
    });
  }

  queryNetworkDwellByLocationByPage(page: number) {
    let networkDwellFilters = this.dashboardReportFormService.getFiltersByDashboard<NetworkDwellFilters>(DashboardType.NetworkDwell);
    networkDwellFilters.page = page;
    networkDwellFilters.pageSize = this.dwellByLocationTablePagingState.take!;
    networkDwellFilters.years = this.years;
    networkDwellFilters.months = this.months;
    networkDwellFilters.locationType = this.graphLocationType;
    networkDwellFilters.total = this.total;

    let callbackTransformFunction =
      (networkDwellStatus$: Observable<PagedResult<NetworkDwellStatusRecord, NetworkDwellFilters>>) => this.pivotStatusRecords(networkDwellStatus$);

    return this.networkDwellDataService.getNetworkDwellByStatusTableData(networkDwellFilters, callbackTransformFunction).result$;
  }

  pivotStatusRecords(networkDwellStatusRecords$: Observable<PagedResult<NetworkDwellStatusRecord, NetworkDwellFilters>>) {
    return networkDwellStatusRecords$.pipe(map((pagedNetworkDwellStatusRecords) => {
      let gridDataResult: GridDataResult = {
        data: [],
        total: pagedNetworkDwellStatusRecords.total
      };

      let networkDwellStatusRecords = pagedNetworkDwellStatusRecords.results;

      let templateGridObject = this.getTemplateGridObject(networkDwellStatusRecords);
      let currentStateProvince = '';

      for (let networkDwellStatusRecord of networkDwellStatusRecords.sort(sortBy('stateProvince', 'station', '-dwellType'))) {
        let gridRow: NetworkDwellStatusGridRow = structuredClone(templateGridObject);

        if (currentStateProvince != networkDwellStatusRecord.stateProvince) {
          gridRow['isNewStateProvince'] = true;
          currentStateProvince = networkDwellStatusRecord.stateProvince;
        }

        gridRow.stateProvince = networkDwellStatusRecord.stateProvince;
        gridRow.station = networkDwellStatusRecord.station;
        gridRow.dwellType = networkDwellStatusRecord.dwellType;

        gridRow.average = _.meanBy(networkDwellStatusRecord.periods, x => x.dwellDifference);

        for (let period of networkDwellStatusRecord.periods) {
          gridRow[this.getMonthYearPropertyName(period.periodStarting)] = period.dwellDifference;
        }

        gridDataResult.data.push(gridRow);
      }

      return gridDataResult;
    }));
  }

  getMonthYearPropertyName(currentMonth: Date) {
    let currentMonthString = formatDate(currentMonth, 'LLL Y', 'en-us');
    return currentMonthString.replace(' ', '');
  }

  getTemplateGridObject(networkDwellStatusRecords: NetworkDwellStatusRecord[]): NetworkDwellStatusGridRow {
    let gridRow: NetworkDwellStatusGridRow = {
      columns: []
    };

    let currentMonth = new Date(networkDwellStatusRecords[0].beginMonth);
    let endMonth = new Date(networkDwellStatusRecords[0].endMonth);

    while (currentMonth <= endMonth) {
      let currentMonthString = formatDate(currentMonth, 'LLL Y', 'en-us');
      gridRow.columns!.push(currentMonthString);
      gridRow[currentMonthString.replace(' ', '')] = undefined;

      currentMonth = addMonths(currentMonth, 1);
    }

    return gridRow;
  }

  public getChartSeriesDataDaily(networkDwellCountRecords: NetworkDwellCount[]) {
    let railcars: ChartSeriesData[] = [];

    let labelSkip = 1;
    let skipLabelStartCount = 500;
    let labelIndex = 0;
    let nextLabelToShow = 0;
    let currentLabelMidpoint = 1;
    let currentPeriod = "";

    if (networkDwellCountRecords.length < skipLabelStartCount) {
      labelSkip = 0;
    }

    networkDwellCountRecords.sort(sortBy('year', 'month', 'day')).forEach((networkDwellCount) => {
      let periodText = formatDate(`${networkDwellCount.year}-${networkDwellCount.month}-01`, 'MMM yyyy', 'en-US');
      let dateText = formatDate(`${networkDwellCount.year}-${networkDwellCount.month}-${networkDwellCount.day}`, 'MMM dd yyyy', 'en-US');

      if (periodText != currentPeriod) {
        currentLabelMidpoint = Math.round(networkDwellCount.day! / 2);
        currentPeriod = periodText;
      }

      let dataPoint: ChartSeriesData = {
        label: currentPeriod.replace(' ', '{br}'),
        value: (networkDwellCount.averageDwell).toString(),
        tooltext: `${dateText}{br}${networkDwellCount.averageDwell} Days`
      };

      if (networkDwellCount.day == currentLabelMidpoint) {
        if (nextLabelToShow == labelIndex) {
          dataPoint.showLabel = "1";
          nextLabelToShow = labelIndex + labelSkip + 1;
        }

        labelIndex = labelIndex + 1;
      }

      railcars.push(dataPoint);
    });

    return railcars;
  }

  public getChartSeriesDataWeekly(networkDwellCountRecords: NetworkDwellCount[]) {
    let railcars: ChartSeriesData[] = [];

    let labelSkip = 13;
    let skipLabelStartCount = 26;
    let labelIndex = 0;
    let nextLabelToShow = 0;
    let currentPeriod = "";

    if (networkDwellCountRecords.length < skipLabelStartCount) {
      labelSkip = 0;
    }

    networkDwellCountRecords.sort(sortBy('year', 'week')).forEach((networkDwellCount) => {
      let periodText = `${networkDwellCount.year}{br}Week ${networkDwellCount.week}`;
      let dateText = `${networkDwellCount.year}{br}Week ${networkDwellCount.week}`;

      if (periodText != currentPeriod) {
        currentPeriod = periodText;
      }

      let dataPoint: ChartSeriesData = {
        label: currentPeriod.replace(' ', '{br}'),
        value: (networkDwellCount.averageDwell).toString(),
        tooltext: `${dateText}{br}${networkDwellCount.averageDwell} Days`
      };

      if (nextLabelToShow == labelIndex) {
        dataPoint.showLabel = "1";
        nextLabelToShow = labelIndex + labelSkip + 1;
      }

      labelIndex = labelIndex + 1;

      railcars.push(dataPoint);
    });

    return railcars;
  }

  public getChartSeriesDataMonthly(networkDwellCountRecords: NetworkDwellCount[]) {
    let railcars: ChartSeriesData[] = [];

    let labelSkip = 3;
    let skipLabelStartCount = 24;
    let labelIndex = 0;
    let nextLabelToShow = 0;
    let currentPeriod = "";

    if (networkDwellCountRecords.length < skipLabelStartCount) {
      labelSkip = 0;
    }

    networkDwellCountRecords.sort(sortBy('year', 'month')).forEach((networkDwellCount) => {
      let month = formatDate(`${networkDwellCount.year}-${networkDwellCount.month}-01`, 'MMM yyyy', 'en-us');
      let periodText = month;
      let dateText = month;

      if (periodText != currentPeriod) {
        currentPeriod = periodText;
      }

      let dataPoint: ChartSeriesData = {
        label: currentPeriod.replace(' ', '{br}'),
        value: (networkDwellCount.averageDwell).toString(),
        tooltext: `${dateText}{br}${networkDwellCount.averageDwell} Days`
      };

      if (nextLabelToShow == labelIndex) {
        dataPoint.showLabel = "1";
        nextLabelToShow = labelIndex + labelSkip + 1;
      }

      labelIndex = labelIndex + 1;

      railcars.push(dataPoint);
    });

    return railcars;
  }

  queryTotalChartData() {
    let filters = this.dashboardReportFormService.getFiltersByDashboard<NetworkDwellFilters>(DashboardType.NetworkDwell);
    filters.period = this.period;
    filters.months = this.months;
    filters.years = this.years;

    let callbackTransformFunction =
      (networkDwellCountRecords$: Observable<NetworkDwellCount[]>) => this.populateTotalChartWithData(networkDwellCountRecords$, this.period);

    this.dwellTotalChartResult$ = this.networkDwellDataService.getNetworkDwellCount(filters, callbackTransformFunction).result$;
  }

  populateTotalChartWithData(networkDwellCountRecords$: Observable<NetworkDwellCount[]>, period: string) {
    return networkDwellCountRecords$.pipe(map((networkDwellCountRecords) => {
      let dwellChartSeriesData: ChartSeriesData[] = [];

      switch (period) {
        case Period.Daily:
          dwellChartSeriesData = this.getChartSeriesDataDaily(networkDwellCountRecords);
          break;
        case Period.Weekly:
          dwellChartSeriesData = this.getChartSeriesDataWeekly(networkDwellCountRecords);
          break;
        case Period.Monthly:
          dwellChartSeriesData = this.getChartSeriesDataMonthly(networkDwellCountRecords);
          break;
        case Period.Quarterly:
          dwellChartSeriesData = this.getChartSeriesDataQuarterly(networkDwellCountRecords);
          break;
        case Period.Annually:
          dwellChartSeriesData = this.getChartSeriesDataAnnually(networkDwellCountRecords);
          break;
      }

      let dataSource: DataSource = {
        "chart": {
          "yAxisName": "Average Dwell (Days) Count",
          "theme": "fusion",
          "showLabels": "0",
          "labelDisplay": "none",
          "paletteColors": "black",
          "plotHoverEffect": false
        },
        "data": dwellChartSeriesData,
        rawData: networkDwellCountRecords
      };

      return dataSource;
    }));
  }

  public getChartSeriesDataQuarterly(networkDwellCountRecords: NetworkDwellCount[]) {
    let railcars: ChartSeriesData[] = [];

    let labelSkip = 0;
    let skipLabelStartCount = 1;
    let labelIndex = 0;
    let nextLabelToShow = 0;
    let currentPeriod = "";

    if (networkDwellCountRecords.length < skipLabelStartCount) {
      labelSkip = 0;
    }

    networkDwellCountRecords.sort(sortBy('year', 'quarter')).forEach((networkDwellCount) => {
      let periodText = `Q${networkDwellCount.quarter} ${networkDwellCount.year}`;
      let dateText = periodText;

      if (periodText != currentPeriod) {
        currentPeriod = periodText;
      }

      let dataPoint: ChartSeriesData = {
        label: currentPeriod.replace(' ', '{br}'),
        value: (networkDwellCount.averageDwell).toString(),
        tooltext: `${dateText}{br}${networkDwellCount.averageDwell} Days`
      };

      if (nextLabelToShow == labelIndex) {
        dataPoint.showLabel = "1";
        nextLabelToShow = labelIndex + labelSkip + 1;
      }

      labelIndex = labelIndex + 1;

      railcars.push(dataPoint);
    });

    return railcars;
  }

  public getChartSeriesDataAnnually(networkDwellCountRecords: NetworkDwellCount[]) {
    let railcars: ChartSeriesData[] = [];

    let labelSkip = 0;
    let skipLabelStartCount = 1;
    let labelIndex = 0;
    let nextLabelToShow = 0;
    let currentPeriod = "";

    if (networkDwellCountRecords.length < skipLabelStartCount) {
      labelSkip = 0;
    }

    networkDwellCountRecords.sort(sortBy('year')).forEach((networkDwellCount) => {
      let periodText = `${networkDwellCount.year}`;
      let dateText = periodText;

      if (periodText != currentPeriod) {
        currentPeriod = periodText;
      }

      let dataPoint: ChartSeriesData = {
        label: currentPeriod.replace(' ', '{br}'),
        value: (networkDwellCount.averageDwell).toString(),
        tooltext: `${dateText}{br}${networkDwellCount.averageDwell} Days`
      };

      if (nextLabelToShow == labelIndex) {
        dataPoint.showLabel = "1";
        nextLabelToShow = labelIndex + labelSkip + 1;
      }

      labelIndex = labelIndex + 1;

      railcars.push(dataPoint);
    });

    return railcars;
  }

  exportData() {
    this.sub.sink = combineLatest({
      summary: this.dwellSummaryQueryResult$!,
      location: this.dwellLocationQueryResult$!,
      commodity: this.dwellCommodityQueryResult$!,
      station: this.dwellStationQueryResult$!,
      dwellByLocationType: this.mapLocationType == LocationType.StateProvince ? this.stateProvinceQueryResult$! : this.stationQueryResult$!,
      dwellByLocation: this.dwellByLocationQueryResult$!,
      dwellByLocationTable: this.dwellByLocationTableResult$!,
      dwellTotal: this.dwellTotalChartResult$!
    }).subscribe(({summary, location, commodity, station, dwellByLocationType, dwellByLocation, dwellByLocationTable, dwellTotal}) => {
      let workbook = this.dataExportService.createWorkbook();

      this.dataExportService.addWorksheet(workbook, 'Summary', [summary.data!]);
      this.dataExportService.addWorksheet(workbook, 'Location', [location.data!]);
      this.dataExportService.addWorksheet(workbook, 'Commodity', [commodity.data!]);
      this.dataExportService.addWorksheet(workbook, 'Station', [station.data!]);

      this.dataExportService.addWorksheet(workbook, 'Dwell By Location Type', dwellByLocationType.data ?? []);

      this.dataExportService.addWorksheet(workbook, 'Dwell By Location', dwellByLocation.data?.rawData ?? []);

      let dwellDifferenceWorksheet = this.dataExportService.addWorksheet(workbook, 'Average Dwell By Location Difference', dwellByLocationTable.data?.data ?? []);
      dwellDifferenceWorksheet.deleteColumnKey('columns');
      dwellDifferenceWorksheet.deleteColumnKey('isNewStateProvince');

      this.dataExportService.addWorksheet(workbook, 'Dwell Total', dwellTotal.data?.rawData ?? []);
      
      this.dataExportService.saveWorkbook(workbook, 'NetworkDwell');

      this.dashboardReportFormService.exportDataComplete$.next();
    });
  }
}
