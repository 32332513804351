import { sortBy } from "sort-by-typescript";
import { Category } from "./category";

export class Categories {
    category: Category[] = [];

    static getCategoriesFromStringList(list: string[]): Categories {
        return {
            "category": list.map(x => ({
                "label": x
            }))
        };
    }
}