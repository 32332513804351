import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LocationType, LocationTypes } from '../../../../constants/location-type';
import { NetworkDwellDataService } from '../../../../services/network-dwell-data.service';
import { mergeMap, from, map, filter, BehaviorSubject, Observable, combineLatest, startWith } from 'rxjs';
import MapView from '@arcgis/core/views/MapView';
import { DashboardDwellMapService } from './dashboard-dwell-map.service';
import { QueryObserverResult } from '@tanstack/query-core';
import { NetworkDwellAverageByStateProvince } from '../../../../models/network-dwell/network-dwell-average-by-state-province';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { NetworkDwellAverageByStation } from '../../../../models/network-dwell/network-dwell-average-by-station';
import { DashboardNetworkDwellFormService } from '../services/dashboard-network-dwell-form.service';
import { sortBy } from 'sort-by-typescript';
import { SubSink } from 'subsink';
import { DashboardReportFormService } from '../../dashboard-report-layout/services/dashboard-report-form.service';
import { NetworkDwellFilters } from '../../../../models/filters/network-dwell-filters';
import { DashboardType } from '../../../../constants/dashboard-type';

@Component({
  selector: 'app-dashboard-dwell-by-location-map',
  templateUrl: './dashboard-dwell-by-location-map.component.html',
  styleUrl: './dashboard-dwell-by-location-map.component.css',
})
export class DashboardDwellByLocationMapComponent implements OnInit, OnDestroy {
  public locationTypeFilters = LocationTypes;

  colorVariableStops$ = this.dashboardDwellMapService.colorVariableStops$;

  @ViewChild('mapViewNode', { static: true }) private mapViewEl!: ElementRef;
  @ViewChild('dwellAverageStateProvinceTable') private dwellAverageStateProvinceGrid!: GridComponent;
  @ViewChild('dwellAverageStationTable') private dwellAverageStationGrid!: GridComponent;

  private sub = new SubSink();

  filterForm = this.formService.filterForm;
  sortBy = sortBy;
  isMapExpanded = false;

  constructor(private networkDwellDataService: NetworkDwellDataService,
    public formService: DashboardNetworkDwellFormService,
    private dashboardReportFormService: DashboardReportFormService,
    private changeDetectorRef: ChangeDetectorRef,
    private dashboardDwellMapService: DashboardDwellMapService
  ) {
    this.sub.sink = this.dashboardDwellMapService.stateProvinceSelected$.subscribe((stateProvinceName) => {
      if (this.dwellAverageStateProvinceGrid?.data) {
        let stateProvinceRecords = this.dwellAverageStateProvinceGrid.data as NetworkDwellAverageByStateProvince[];
        let stateProvinceIndex = stateProvinceRecords.findIndex(x => x.stateProvinceName == stateProvinceName);

        this.dwellAverageStateProvinceGrid.scrollTo({
          row: stateProvinceIndex,
          column: 0
        });

        this.changeDetectorRef.detectChanges();
      }
    });

    this.sub.sink = this.dashboardDwellMapService.stationSelected$.subscribe((station) => {
      if (this.dwellAverageStationGrid?.data) {
        let stationRecords = this.dwellAverageStationGrid.data as NetworkDwellAverageByStation[];
        let stationIndex = stationRecords.findIndex(x => x.city == station);

        this.dwellAverageStationGrid.scrollTo({
          row: stationIndex,
          column: 0
        });

        this.changeDetectorRef.detectChanges();
      }
    });
  }

  get stationQueryResult$() {
    return this.formService.stationQueryResult$;
  }

  get stateProvinceQueryResult$() {
    return this.formService.stateProvinceQueryResult$;
  }

  get showStateProvince() {
    return this.formService.mapLocationType === LocationType.StateProvince;
  }

  get showStation() {
    return this.formService.mapLocationType === LocationType.Station;
  }

  ngOnInit(): any {
    this.formService.loadStateProvinceMapWithData(this.mapViewEl, this.expandButtonClick);
    this.formService.queryLocationMapData(this.mapViewEl, this.expandButtonClick);

    this.sub.sink = combineLatest([
      this.formService.yearsFormControl.valueChanges.pipe(startWith([])), 
      this.formService.monthsFormControl.valueChanges.pipe(startWith([])),
      this.formService.mapLocationTypeControl.valueChanges.pipe(startWith(undefined)),
      this.dashboardReportFormService.filtersApplied$.pipe(startWith(undefined as void)),
    ]).subscribe(() => {
        this.formService.queryLocationMapData(this.mapViewEl, this.expandButtonClick);
    });
  }

  stateProvinceRowCallback = (context: RowClassArgs) => {
    let stateProvinceName: string = context.dataItem['stateProvinceName'];
    let selectedStateProvinceName = this.dashboardDwellMapService.stateProvinceSelected$.value;

    return {
      'state-province-row-selected': selectedStateProvinceName === stateProvinceName
    };
  };

  stationRowCallback = (context: RowClassArgs) => {
    let station: string = context.dataItem['city'];
    let selectedStation = this.dashboardDwellMapService.stationSelected$.value;

    return {
      'station-row-selected': selectedStation === station
    };
  };

  

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  locationTypeFilterChange(event: any) {
    let locationTypeIndex = event[0];
    let locationType = this.locationTypeFilters[locationTypeIndex];

    this.formService.mapLocationTypeControl.setValue(locationType);
  }

  private expandButtonClick = () => {
    this.isMapExpanded = !this.isMapExpanded;
    this.changeDetectorRef.detectChanges();
  }
}
